import {
    takeLatest, put, call,
} from 'redux-saga/effects';
import { getAllLanguages, getAllLocations, getRecommendedJobTitles, getRecommendedSkillTitles } from "../../utils/apiCalls";
import { fetchRecommendedLabels, setRecommendedJobs, setRecommendedSkills, fetchLocations, setAvailableLocations, setAvailableLanguages, fetchLanguages } from './searchSlice';

function* fetchRecommendedLabelsSaga(query) {
    try {
        let matchedJobLabels = [];
        if (query.payload.length >= 3) {
            const jobs = yield call(getRecommendedJobTitles, query.payload);
            matchedJobLabels = jobs.map((title) => ({
                ...title,
                selected: false
            }));
        }

        const skills = yield call(getRecommendedSkillTitles, query.payload);
        const matchedSkillLabels = skills.map((title) => ({ skillTitle: title, selected: false }));

        yield put(setRecommendedJobs(matchedJobLabels))
        yield put(setRecommendedSkills(matchedSkillLabels))
    } catch (error) {
        console.log('New error', error)
    }
}

function* fetchLocationsSaga() {
    try {
        const locations = yield call(getAllLocations);
        yield put(setAvailableLocations(locations))
    } catch (error) {
        console.log('New error', error)
    }
}

function* fetchLanguagesSaga() {
    try {
        const languages = yield call(getAllLanguages);
        yield put(setAvailableLanguages(languages))
    } catch (error) {
        console.log('New error', error)
    }
}

export function* watchSearchSaga() {
    yield takeLatest(fetchRecommendedLabels.type, fetchRecommendedLabelsSaga)
    yield takeLatest(fetchLocations.type, fetchLocationsSaga)
    yield takeLatest(fetchLanguages.type, fetchLanguagesSaga)

}