import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container, Modal, Stack } from '@mui/material';
import { forgotPassword } from '../loginComponent/authSlice';
import Avatar from '@mui/material/Avatar';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const style = {
    position: 'absolute',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

export default function ForgotPasswordModal({ open, close }) {
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        dispatch(forgotPassword(email));
        close();
    };

    return (
        <Modal
            open={open}
            onClose={close}
        >
            <Box sx={style}>
                <Avatar sx={{ mb: 1, bgcolor: 'secondary' }}>
                    <VpnKeyIcon />
                </Avatar>
                <Typography variant="h5">
                    <FormattedMessage id='resetPassword' />
                </Typography>
                <Container component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label={<FormattedMessage id='email' />}
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Stack direction={'row'} spacing={4} mt={3}>
                        <Button variant="contained" color="primary" fullWidth onClick={close}>
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button type="submit" variant="contained" color="success" fullWidth>
                            <FormattedMessage id="submit" />
                        </Button>
                    </Stack>
                </Container>
            </Box>
        </Modal>
    );
}