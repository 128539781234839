import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#EB415A',
        dark: '#2B2E34',
        light: '#ed9595',
      },
      secondary: {
        main: '#FAF9F6',
        dark: '#2B2E34',
      },
      success: {
        main: '#2B2E34',
        dark: '#2B2E34',
        light: '#2B2E34',
      },
    },
    typography: {
      h1: {
        fontFamily: 'Futura-Bold',
      },
      h2: {
        fontFamily: 'Futura-Bold',
      },
      h3: {
        fontFamily: 'Futura-Bold',
      },
      h4: {
        fontFamily: 'Futura-Bold',
      },
      h5: {
        fontFamily: 'Futura-Bold',
      },
      h6: {
        fontFamily: 'Futura-Bold',
        fontSize: 17
      },
      h7: {
        fontFamily: 'Futura-Bold',
        fontSize: 15
      },
      body1: {
        fontFamily: 'Futura-Book',
        fontSize: 18
      },
      body2: {
        fontFamily: 'Futura-Book',
        fontSize: 16
      },
      subtitle1: {
        fontFamily: 'Futura-Book',
      },
      subtitle2: {
        fontFamily: 'Futura-Book',
      },
      button: {
        fontFamily: 'Futura-Book',
        fontSize: 16
      },
      caption: {
        fontFamily: 'Futura-Book',
      },
      overline: {
        fontFamily: 'Futura-Book',
        fontSize: 13
      }
    },
  });

  export default theme;