import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { FormattedMessage } from 'react-intl'

export default function SearchField (props) {

    const handleChange = (e) => {
        props.onSearchChange(e.target.value)
    }

    return (
        <Box
        id='searchfield' 
          sx={{
            width: 500,
            maxWidth: '100%',
          }}
        >
          <TextField
            fullWidth
            label={<FormattedMessage id="search" />}
            id="fullWidth" 
            value={props.searchValue}
            onChange={(e) => handleChange(e)}
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color='action' />
                  </InputAdornment>
                ),
              }}           
             />
        </Box>
      );
}