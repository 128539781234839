import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, TextField, Typography } from '@mui/material'
import {
  changeUserPassword, profileSliceSelector,
} from '../profilePage/profileSlice'
import Modal from '@mui/material/Modal'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

export default function ChangePasswordModal(props) {
  const dispatch = useDispatch()
  const { _id } = useSelector(profileSliceSelector)

  const [enableButton, setEnableButton] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')

  const handleClose = () => {
    props.close()
    setOldPassword('')
    setNewPassword('')
    setRepeatNewPassword('')
    setEnableButton(false)
  }

  const save = (e) => {
    e.preventDefault()
    const body = {
      _id,
      oldPassword,
      newPassword,
      repeatNewPassword,
    }
    dispatch(changeUserPassword(body))
  }

  useEffect(() => {
    const filledAll = oldPassword && newPassword && repeatNewPassword
    const correctNewPasswrod = newPassword === repeatNewPassword

    if (filledAll && correctNewPasswrod) {
      setEnableButton(true)
    } else {
      setEnableButton(false)
    }
  }, [oldPassword, newPassword, repeatNewPassword])

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component='h1' variant='h5'>
              <FormattedMessage id='changePassword' />
            </Typography>
            <Box component='form' onSubmit={save} noValidate sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                label={<FormattedMessage id='oldPassword' />}
                autoFocus
                value={oldPassword}
                onChange={(event) => setOldPassword(event.target.value)}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                label={<FormattedMessage id='newPassword' />}
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                label={<FormattedMessage id='repeatNewPassword' />}
                value={repeatNewPassword}
                onChange={(event) => setRepeatNewPassword(event.target.value)}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                disabled={!enableButton}
              >
                <FormattedMessage id='save' />
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
