import * as React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Stack, Divider, Link } from '@mui/material';
import { BiLogoXing } from 'react-icons/bi'

export default function Footer() {
    return (
        <Paper sx={{ position: 'relative', display: 'flex', justifyContent: 'center', zIndex: 1000, width: '100%', height: '15rem', backgroundColor: 'secondary.dark', color: 'secondary.main' }} >
            <Stack justifyContent={'space-between'} width={'80%'}>
                <Stack pt={3} pb={3} direction="row" justifyContent="space-between">
                    <Typography variant={'body1'} fontSize={20}>
                        maisourcer GmbH
                    </Typography>
                    <Stack >
                        <Typography variant={'body1'} fontSize={20} mb={2}>
                            Zum Handelshof 3
                        </Typography>
                        <Typography variant={'body1'} fontSize={20} >
                            39108 Magdeburg
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography variant={'body1'} fontSize={20} mb={2}>
                            +49 173 1630276
                        </Typography>
                        <Link variant={'body1'} fontSize={20} href="mailto: jesido@maisourcer.de" color="inherit">
                            jesido@maisourcer.de
                        </Link>
                    </Stack>
                    <Stack direction="row" spacing={1.5}>
                        <FacebookIcon fontSize='large' />
                        <InstagramIcon fontSize='large' />
                        <BiLogoXing size={'2rem'} />
                        <LinkedInIcon fontSize='large' />
                    </Stack>
                </Stack>
                <Stack pt={3} pb={3} direction="row" justifyContent="space-between" alignItems={'center'} spacing={2}>
                    <Typography variant={'body1'} fontSize={20}>© 2023 maisourcer GmbH</Typography>
                    <Stack direction="row" justifyContent={'space-between'}>
                        <Link m={2} variant={'body1'} fontSize={20} href="https://www.maisourcer.de/imprint/" color="inherit" target="_blank" rel="noopener" underline="none">Impressum</Link>
                        <Divider orientation="vertical" variant="middle" flexItem color={'whitesmoke'} />
                        <Link m={2} variant={'body1'} fontSize={20} href="https://www.maisourcer.de/privacy/" color="inherit" target="_blank" rel="noopener" underline="none">Datenschutz</Link>
                    </Stack >
                </Stack></Stack>
        </Paper>
    )
}