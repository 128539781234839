import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as RouterLink} from 'react-router-dom';
import { signIn } from './authSlice';
import { useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ForgotPasswordModal from '../modals/ForgotPasswordModal';

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openForgotPassModal, setOpenForgotPassModal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const user = {
      email: data.get('email'),
      password: data.get('password'),
    }
    const signInData = {
      user,
      authType: 'credentials',
      navigate,
      navTo: '/'
    }
    dispatch(signIn(signInData));
  };

  const handleForgotPassClick = () => {
    setOpenForgotPassModal(true)
  }

  const closeForgotPassModal = () => {
    setOpenForgotPassModal(false)
  }


  return (
      <Container maxWidth="xs" sx={{ mt: 20, mb:20 }}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            height: '100%' ,
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <FormattedMessage id='signin' />
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={<FormattedMessage id='email' />}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={<FormattedMessage id='password' />}
              type="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <FormattedMessage id='signin' />
            </Button>
            <Grid container >
                <RouterLink to='/sign-up' style={{ textDecoration: 'none' }}>
                <Link variant="body1" color='secondary.dark'>
                  {<FormattedMessage id='dontHaveAccount' />}
                  </Link>
                </RouterLink>
                <Link href="#" onClick={handleForgotPassClick} variant="body2" color='secondary.dark'>
                <FormattedMessage id="forgotPassword" />
                </Link>
            </Grid>
          </Box>
        </Box>
        <ForgotPasswordModal open={openForgotPassModal} close={closeForgotPassModal} />
      </Container>
  );
}