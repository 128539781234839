import { createSlice } from "@reduxjs/toolkit";

const SEARCH_SLICE = 'SEARCH_SLICE'

const filtersInitialState = {
    availableLocations: [],
    availableLanguages: [],
    recommendedJobs: [],
    recommendedSkills: [],
    filter: {
        location: [],
        language: [],
        jobTitle: [],
        skillTitle: [],
        startDate: {
            min: 0,
            max: 0,
        },
        workModel: [],
        level: [],
        salary: {
            min: 0,
            max: 0,
        },
        contractType: [],

    },
    page: 0,
    pageSize: 12
}

const searchSlice = createSlice({
    name: SEARCH_SLICE,
    initialState: filtersInitialState,
    reducers: {
        resetFiltersReducer: (state, action) => {
            state.filter = {
                location: [],
                language: [],
                jobTitle: [],
                skillTitle: [],
                startDate: {
                    min: 0,
                    max: 0,
                },
                workModel: [],
                level: [],
                salary: {
                    min: 0,
                    max: 0,
                },
                contractType: [],
                page: 0,
                pageSize: 6
            }
        },
        setAvailableLocations: (state, action) => {
            state.availableLocations = action.payload
        },
        setAvailableLanguages: (state, action) => {
            state.availableLanguages = action.payload
        },
        setRecommendedJobs: (state, action) => {
            state.recommendedJobs = action.payload.filter(label =>
                state.filter.jobTitle.length !== 0
                    ? !state.filter.jobTitle.some(selectedLabel => selectedLabel.jobTitle === label.jobTitle)
                    : true
            );
        },
        addRecommendedJob: (state, action) => {
            let obj = { jobTitle: action.payload, selected: false }
            state.recommendedJobs = [...state.recommendedJobs, obj]
        },
        removeRecommendedJob: (state, action) => {
            state.recommendedJobs = state.recommendedJobs.filter(label => label.jobTitle !== action.payload);
        },
        setRecommendedSkills: (state, action) => {
            state.recommendedSkills = action.payload.filter(label =>
                state.filter.skillTitle.length !== 0
                    ? !state.filter.skillTitle.some(selectedLabel => selectedLabel.skillTitle === label.skillTitle)
                    : true
            );
        },
        addRecommendedSkill: (state, action) => {
            let obj = { skillTitle: action.payload, selected: false }
            state.recommendedSkills = [...state.recommendedSkills, obj]
        },
        removeRecommendedSkill: (state, action) => {
            state.recommendedSkills = state.recommendedSkills.filter(label => label.skillTitle !== action.payload);
        },
        setLocation: (state, action) => {
            state.filter.location = action.payload
        },
        setLanguage: (state, action) => {
            state.filter.language = action.payload
        },
        removeArrayFormFilter: (state, action) => {
            state.filter[action.payload.key] = state.filter[action.payload.key].filter(label => label !== action.payload.label)
        },
        setJobTitle: (state, action) => {
            state.filter.jobTitle = action.payload
        },
        addJobTitle: (state, action) => {
            let obj = { jobTitle: action.payload, selected: true }
            state.filter.jobTitle = [...state.filter.jobTitle, obj]
        },
        editJobTitle: (state, action) => {
            state.filter.jobTitle = state.filter.jobTitle.map(label =>
                label.jobTitle === action.payload ? { ...label, selected: !label.selected } : label
            );
        },
        removeJobTitle: (state, action) => {
            state.filter.jobTitle = state.filter.jobTitle.filter(label => label.jobTitle !== action.payload)
        },
        setSkillTitle: (state, action) => {
            state.filter.skillTitle = action.payload
        },
        addSkillTitle: (state, action) => {
            let obj = { skillTitle: action.payload, selected: true }
            state.filter.skillTitle = [...state.filter.skillTitle, obj]
        },
        editSkillTitle: (state, action) => {
            state.filter.skillTitle = state.filter.skillTitle.map(label =>
                label.skillTitle === action.payload ? { ...label, selected: !label.selected } : label
            );
        },
        removeSkillTitle: (state, action) => {
            state.filter.skillTitle = state.filter.skillTitle.filter(label => label.skillTitle !== action.payload)
        },
        setStartDate: (state, action) => {
            state.filter.startDate.min = action.payload.min
            state.filter.startDate.max = action.payload.max
        },
        setWorkModel: (state, action) => {
            state.filter.workModel = action.payload
        },
        setLevel: (state, action) => {
            state.filter.level = action.payload
        },
        setSalary: (state, action) => {
            state.filter.salary.min = action.payload.min
            state.filter.salary.max = action.payload.max
        },
        setContractTypes: (state, action) => {
            state.filter.contractType = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        fetchRecommendedLabels: () => { },
        fetchLocations: () => { },
        fetchLanguages: () => { }
    }
})

export const {
    resetFiltersReducer,
    setAvailableLocations,
    setAvailableLanguages,
    setRecommendedJobs,
    addRecommendedJob,
    removeRecommendedJob,
    setRecommendedSkills,
    addRecommendedSkill,
    removeRecommendedSkill,
    setContractTypes,
    setLocation,
    setLanguage,
    removeArrayFormFilter,
    setJobTitle,
    addJobTitle,
    editJobTitle,
    removeJobTitle,
    setSkillTitle,
    addSkillTitle,
    editSkillTitle,
    removeSkillTitle,
    setStartDate,
    setWorkModel,
    setLevel,
    setPage,
    setSalary,
    fetchRecommendedLabels,
    fetchLocations,
    fetchLanguages
} = searchSlice.actions;

export const searchSliceSelector = (state) => state.searchState;

export default searchSlice.reducer