import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import EuroIcon from '@mui/icons-material/Euro';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LocationsFilters from './components/locationsFilters'
import WorkModelFilters from './components/workModelFilters'
import SalaryFilters from './components/salaryFilters'
import LevelFilters from './components/levelFilters'
import StartFilters from './components/startFilters'
import { FormattedMessage, useIntl } from 'react-intl'
import { Chip, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { removeArrayFormFilter, resetFiltersReducer, searchSliceSelector, setSalary, setStartDate } from './searchSlice'
import EditNoteIcon from '@mui/icons-material/EditNote';
import ContractTypeFilters from './components/contractTypeFilters'
import _ from 'lodash';
import NumbersIcon from '@mui/icons-material/Numbers';
import LanguagesFilters from './components/languageFilters'
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import './../../style/style.js';

const commonStyles = {
  overflowX: 'hidden',
  backgroundColor: '#2B2E34',
  // Custom scrollbar styling for WebKit browsers
  '&::-webkit-scrollbar': {
    width: '3px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#2B2E34',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '2px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',
  },
  // Custom scrollbar styling for Firefox
  scrollbarWidth: 'thin',
  scrollbarColor: '#888 #2B2E34',
};

const openedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.up('xs')]: {
    width: '40%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '30%',
  },
  [theme.breakpoints.up('md')]: {
    width: '25%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '20%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '14%',
  },
  overflowY: 'auto',
  ...commonStyles,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  ...commonStyles,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  paddingTop: 60,
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function SideBar() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isMobile = useMediaQuery('(max-width:800px)');
  const { filter } = useSelector(searchSliceSelector)
  const [open, setOpen] = useState(!isMobile);
  const [showFilter, setShowFilter] = useState({
    location: false,
    language: false,
    start: false,
    workModel: false,
    level: false,
    salary: false,
    contractType: false,
  })

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  const handleDeleteLabel = (unit) => {
    unit === 'W' ? dispatch(setStartDate({ min: 0, max: 0 })) : dispatch(setSalary({ min: 0, max: 0 }));
  }

  const renderChip = (min, max, unit) => {
    return (min !== 0 || max !== 0) && (
      <Grid item>
        <Chip size="small" label={`${min}-${max}${unit}`} color='secondary' onDelete={() => handleDeleteLabel(unit)}
        />
      </Grid>
    );
  };

  const handleDeleteArrayLabel = (filterKey, item) => {
    dispatch(removeArrayFormFilter({ key: filterKey, label: item }));
  }

  const FilterChips = () => (
    <Grid container spacing={1} padding={1} ml={0.5}>
      {['location', 'language', 'workModel', 'contractType', 'level'].map((filterKey) =>
        filter[filterKey]?.map((item) => (
          <Grid item key={item}>
            <Chip size="small" onDelete={() => handleDeleteArrayLabel(filterKey, item)} label={_.capitalize(intl.formatMessage({ id: item }))} color='secondary' />
          </Grid>
        ))
      )}
      {renderChip(filter.startDate.min, filter.startDate.max, 'W')}
      {renderChip(filter.salary.min, filter.salary.max, '€')}
    </Grid>
  );

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleResetClick = (e) => {
    e.preventDefault()
    dispatch(resetFiltersReducer())
  }

  const handleShowFilter = (keyToSetTrue) => {
    setShowFilter((prevShowFilter) => {
      const updatedShowFilter = {};
      Object.keys(prevShowFilter).forEach((key) => {
        updatedShowFilter[key] =
          key === keyToSetTrue ? !prevShowFilter[keyToSetTrue] : false;
      });
      return updatedShowFilter;
    });
  }

  const iconColour = '#FAF9F6'

  return (

    <Drawer
      variant='permanent'
      open={open}
      sx={{ zIndex: 10 }}
    >
      <DrawerHeader />
      {isMobile
        &&
        <IconButton>
          <MenuIcon color='secondary'
            onClick={handleOpen}
            sx={{ mt: 2 }} />
        </IconButton>
      }
      <List >
        <Stack
          direction='row'
          sx={{ display: open ? 'flex' : 'none', ml: 3, mt: 4, mb: 2 }}>
          <Typography color='primary' variant='h6'><FormattedMessage id="filters" /></Typography>
          <Typography
            onClick={handleResetClick}
            sx={{ textDecoration: 'underline', ml: 1, cursor: 'pointer' }}
            color='primary'
          >
            <FormattedMessage id="clearAll" />
          </Typography>
        </Stack>
      </List>
      {open && <FilterChips />}
      <List>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => handleShowFilter('location')}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <LocationOnIcon sx={{ color: iconColour, fontSize: 25 }} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id='location' />}
              sx={{ opacity: open ? 1 : 0, color: iconColour }}
            />
          </ListItemButton>
        </ListItem>
        {open && showFilter.location && <LocationsFilters />}
      </List>
      <List>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() =>
            handleShowFilter('contractType')
          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <EditNoteIcon sx={{ color: iconColour, fontSize: 35 }} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id='contract' />}
              sx={{ opacity: open ? 1 : 0, color: iconColour, marginLeft: -1 }}
            />
          </ListItemButton>
        </ListItem>
        {open && showFilter.contractType && <ContractTypeFilters />}
      </List>
      <List>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() =>
            handleShowFilter('workModel')

          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <CoPresentIcon sx={{ color: iconColour, fontSize: 25 }} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id='workModel' />}
              sx={{ opacity: open ? 1 : 0, color: iconColour }}
            />
          </ListItemButton>
        </ListItem>
        {open && showFilter.workModel && <WorkModelFilters />}
      </List>
      <List>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() =>
            handleShowFilter('level')

          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <LeaderboardIcon sx={{ color: iconColour, fontSize: 25 }} />
            </ListItemIcon>
            <ListItemText
              primary={'Level'}
              sx={{ opacity: open ? 1 : 0, color: iconColour }}
            />
          </ListItemButton>
        </ListItem>
        {open && showFilter.level && <LevelFilters />}
      </List>
      <List>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => handleShowFilter('language')}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <LanguageIcon sx={{ color: iconColour, fontSize: 25 }} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id='language' />}
              sx={{ opacity: open ? 1 : 0, color: iconColour }}
            />
          </ListItemButton>
        </ListItem>
        {open && showFilter.language && <LanguagesFilters />}
      </List>
      <List>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() =>
            handleShowFilter('start')

          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <EventAvailableIcon sx={{ color: iconColour, fontSize: 25 }} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id='weeksToStart' />}
              sx={{ opacity: open ? 1 : 0, color: iconColour }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      {open && showFilter.start && <StartFilters />}
      <List>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() =>
            handleShowFilter('salary')
          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <EuroIcon sx={{ color: iconColour, fontSize: 25 }} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id='salaryRange' />}
              sx={{ opacity: open ? 1 : 0, color: iconColour }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      {open && showFilter.salary && <SalaryFilters />}
    </Drawer>
  )
}
