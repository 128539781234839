import React, { useEffect } from "react";
import SearchLayout from "./searchFieldComponent/searchLayout";
import EmployeeList from "./employeeCardComponent/EmployeeList";
import SideNav from "./../features/sideBar/sideBar"
import './../style/style.css'
import { Stack, styled } from "@mui/material";

export default function MainComponent() {

  useEffect(() => {
    const searchElement = document.querySelector('.searchLayout');

    const scroll = (event) => {

      if (window.scrollY > 0) {
        searchElement.style.height = '190px';
      } else {
        searchElement.style.height = '400px';
      }

    };

    document.addEventListener("scroll", scroll);

    return () => {
      document.removeEventListener("scroll", scroll);
    }
  }, []);

  // Styled component for the sidebar that adjusts width based on screen size
  const ResponsiveSidebar = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
      width: '10%', // Adjust as needed for 'sm' and up
    },
    [theme.breakpoints.up('sm')]: {
      width: '30%', // Adjust as needed for 'sm' and up
    },
    [theme.breakpoints.up('md')]: {
      width: '25%', // Adjust as needed for 'md' and up
    },
    [theme.breakpoints.up('xl')]: {
      width: '13%', // Adjust as needed for 'lg' and up
    }, 
  }));

  // Styled component for the main content area that adjusts width based on screen size
  const MainContent = styled(Stack)(({ theme }) => ({
    marginTop: 60,
    [theme.breakpoints.up('xs')]: {
      width: '90%', // Adjust as needed for 'sm' and up
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%', // Adjust as needed for 'sm' and up
    },
    [theme.breakpoints.up('md')]: {
      width: '75%', // Adjust as needed for 'md' and up
    },
    [theme.breakpoints.up('xl')]: {
      width: '87%', // Adjust as needed for 'lg' and up
    }, 
  }));



  return (
    <Stack direction={'row'} style={{ width: '100%' }}>
      <Stack className="responsive-sidebar">
        <SideNav />
      </Stack>
      <Stack className="main-content">
        <div className='searchLayout' style={{ transition: 'height 1s ease', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
          <SearchLayout />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '95%' }}>
            <EmployeeList />
          </div>
        </div>
      </Stack>
    </Stack>
  )
}