import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
  name: 'profileState',
  initialState: {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    address: '',
    phoneNumber: '',
    position: '',
    credits: 0,
    customerNumber: '',
    showSignInRegisterModal: false,
    showCreditsModal: false,
    favorites: [],
    favoriteCVs: [],
  },
  reducers: {
    setProfileInfo: (state, action) => {
      state._id = action.payload._id
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.email = action.payload.email
      state.companyName = action.payload.companyName
      state.address = action.payload.address
      state.credits = action.payload.credits
      state.phoneNumber = action.payload.phoneNumber
      state.position = action.payload.position
      state.customerNumber = action.payload.customerNumber
    },
    setShowSignInRegisterModal: (state, action) => {
      state.showSignInRegisterModal = action.payload
    },
    setShowCreditsModal: (state, action) => {
      state.showCreditsModal = action.payload
    },
    addCredits: (state, action) => {
      state.credits += action.payload
    },
    reduceCredits: (state, action) => {
      state.credits -= action.payload
    },
    changeUserInfo: () => {},
    changeUserPassword: () => {},
    buyCredits: () => {},
  },
})

export const {
  changeUserInfo,
  changeUserPassword,
  setProfileInfo,
  setShowCreditsModal,
  addCredits,
  reduceCredits,
  setShowSignInRegisterModal,
  buyCredits,
} = profileSlice.actions

export const profileSliceSelector = (state) => state.profileState

export default profileSlice.reducer
