import { Typography, Stack, Button } from '@mui/material'
import CreditsModal from '../../modals/CreditsModal'
import { useSelector } from 'react-redux'
import { profileSliceSelector } from '../profileSlice'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'

export default function Credits() {
  const { credits } = useSelector(profileSliceSelector)
  const [showCreditsModal, setShowCreditsModal] = useState(false)

  const openModal = () => setShowCreditsModal(true)
  const closeModal = () => setShowCreditsModal(false)

  return (
    <Stack
      direction='column'
      sx={{
        padding: '20px',
        height: 'fit-content'
      }}
    >
      <Typography textAlign={'center'} variant='h5' color="primary.dark">
        {`Credits: ${credits}`}
      </Typography>
      <Button
        fullWidth
        variant='contained'
        sx={{ mt: 2 }}
        onClick={() => openModal()}
      >
        <FormattedMessage id='buyCredits' />
      </Button>
      <CreditsModal open={showCreditsModal} closeModal={closeModal} />
    </Stack>
  )
}

