import { createSlice } from "@reduxjs/toolkit"

export const employeeSlice = createSlice({
    name: 'employeeState',
    initialState: {
        showModal: false,
        selectedCard: '',
        employeeInfo: {},
        employees: [],
        totalEmployees: 0,
        totalFilteredEmployees: 0,
    },
    reducers: {
        setEmpolyeeReducer: (state, action) => {
            state.showModal = action.payload.showModal
            state.employeeInfo = action.payload.employeeInfo
        },
        setSelectedCard: (state, action) => {
            state.selectedCard = action.payload
        },
        setEmployeesReducer: (state, action) => {
            state.employees = [...state.employees, ...action.payload]
        },
        setTotalEmployeesReducer: (state, action) => {
            state.totalEmployees = action.payload
        },
        setTotalFilteredEmployees: (state, action) => {
            state.totalFilteredEmployees = action.payload
        },
        addEmployeesReducer: (state, action) => {
            state.employees  = [...state.employees, action.payload]
        },
        resetEmployeesReducer: (state, action) => {
            state.employees = [];
            state.totalEmployees = 0
        },
        fetchEmployees: () => {},
        filterEmployees: () => {}
    }
})

export const { setEmpolyeeReducer, setSelectedCard, setTotalFilteredEmployees, setEmployeesReducer, setTotalEmployeesReducer, addEmployeesReducer, resetEmployeesReducer, fetchEmployees, filterEmployees } = employeeSlice.actions;
export const selectEmployee = (state) => state.employeeState;

export default employeeSlice.reducer
