import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useSelector } from 'react-redux'
import ChangePersonalInformationModal from '../../modals/ChangePersonalInformationModal'
import ChangePasswordModal from '../../modals/ChangePasswordModal'
import { profileSliceSelector } from '../profileSlice'
import { Box, Button, Link, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'
import DeleteAccountModal from '../../modals/DeleteAccountModal'

export default function Information() {
  const { firstName, lastName, email, phoneNumber, position, companyName, address, customerNumber } =
    useSelector(profileSliceSelector)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [showChangeUserInfoModal, setShowChangeUserInfoModal] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)

  function createData(field, currentValue) {
    return { field, currentValue }
  }

  const rows = [
    createData(<FormattedMessage id='firstName' />, firstName),
    createData(<FormattedMessage id='lastName' />, lastName),
    createData('Email', email),
    createData(<FormattedMessage id='phone' />, phoneNumber),
    createData(<FormattedMessage id='companyName' />, companyName),
    createData(<FormattedMessage id='customerNumber' />, customerNumber),
    createData('Position', position),
    createData(<FormattedMessage id='address' />, address),
  ]

  function handleEditClick() {
    setShowChangeUserInfoModal(true)
  }

  function handleChangePasswordClick() {
    setShowChangePasswordModal(true)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <ChangePersonalInformationModal open={showChangeUserInfoModal} close={() => setShowChangeUserInfoModal(false)} />
        <ChangePasswordModal open={showChangePasswordModal} close={() => setShowChangePasswordModal(false)} />
        <Table sx={{ minWidth: 650 }} aria-label='simple table' >
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.field}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row.field}
                </TableCell>
                <TableCell align='right'>{row.currentValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction={'row'} justifyContent={'space-between'} display={'flex'} mt={5}>
        <Link
          color={'error'}
          variant='body1'
          onClick={() => setOpenDeleteModal(true)}
        >
          <FormattedMessage id='deleteAccount' />
        </Link>
        <Box>
          <Button
            sx={{ mr: 5 }}
            variant='contained'
            onClick={() => handleChangePasswordClick()}
          >
            <FormattedMessage id='changePassword' />
          </Button>
          <Button
            variant='contained'
            onClick={() => handleEditClick()}
          >
            <FormattedMessage id='edit' />
          </Button>
        </Box>
      </Stack>

      <DeleteAccountModal open={openDeleteModal} close={() => setOpenDeleteModal(false)} />
    </>
  )
}
