import './loaderOverlay.css';
import { ThreeDots } from 'react-loader-spinner'
import React, { useState, useEffect } from 'react';

const LoaderOverlay = ({showOverlay}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        let timer;

        if (showOverlay) {
            // Set a timer to display the overlay after a short delay (e.g., 1000 milliseconds or 1 second)
            // Otherwise it will appear as a flash when the response is fast
            timer = setTimeout(() => {
                setVisible(true);
            }, 1000);
        }

        return () => {
            // Clear the timer when the component unmounts or when the response arrives
            clearTimeout(timer);
        };
    }, [showOverlay]);

    return visible ?  (
        <div className={showOverlay ? 'overlay' : 'loader-alone'}>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="rgb(235,64,90)"
                ariaLabel="three-dots-loading"
                visible={true}
                zIndex={2000}
            />
        </div>
    ) : null;
};

export default LoaderOverlay;