import { request } from './request'
import {
  SIGN_IN,
  SIGN_UP,
  TOKEN_AUTH,
  GET_ALL_CVS,
  GET_FILTERED_CVS,
  GET_RECOMMENDED_JOBS,
  GET_RECOMMENDED_SKILLS,
  CHANGE_USER_INFO,
  CHANGE_USER_PASSWORD,
  GET_COMPANIES_ADMIN,
  GET_COMPANIES_BY_ID_ADMIN,
  EDIT_COMPANY_CREDITS,
  CREATE_CV,
  GET_ALL_LOCATIONS,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  GET_ALL_LANGUAGES,
  GET_CV_FOR_MODAL,
  DELETE_ACCOUNT_REQUEST,
  CREATE_PAYMENT_REQUEST,
  GET_ALL_PACKAGES,
} from './apiUrls'

export const signUpCall = async (user) => {
  const requestOptions = {
    url: SIGN_UP,
    method: 'POST',
    body: user,
  };

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const signInCall = async (data) => {
  const requestOptions = {
    url: SIGN_IN,
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const authWithToken = async () => {
  const requestOptions = {
    url: TOKEN_AUTH,
    method: 'POST',
    // token,
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const forgotPasswordCall = async (data) => {
  console.log('got', data)
  const requestOptions = {
    url: FORGOT_PASSWORD,
    method: 'POST',
    // token,
    body: { email: data },
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const resetPasswordCall = async (data) => {
  const requestOptions = {
    url: RESET_PASSWORD,
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getCompaniesForAdmin = async () => {
  const requestOptions = {
    url: GET_COMPANIES_ADMIN,
    method: 'GET',
    // token,
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getCompaniesByIdForAdmin = async (companysIds) => {
  const requestOptions = {
    url: GET_COMPANIES_BY_ID_ADMIN,
    method: 'POST',
    // token,
    body: [companysIds],
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getAllCVs = async () => {
  const requestOptions = {
    url: GET_ALL_CVS,
    method: 'GET',
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getAllLocations = async () => {
  const requestOptions = {
    url: GET_ALL_LOCATIONS,
    method: 'GET',
    // token,
    body: {},
  };

  let response;
  try {
    response = await request(requestOptions);
  } catch (err) {
    if (err.response.data) {
      response = err.response.data;
    }
  }

  return response;
};

export const getAllLanguages = async () => {
  const requestOptions = {
    url: GET_ALL_LANGUAGES,
    method: 'GET',
    // token,
    body: {},
  };

  let response;
  try {
    response = await request(requestOptions);
  } catch (err) {
    if (err.response.data) {
      response = err.response.data;
    }
  }

  return response;
};

export const getFilteredCVs = async (filter) => {
  const requestOptions = {
    url: GET_FILTERED_CVS,
    method: 'POST',
    // token,
    body: filter,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getRecommendedJobTitles = async (jobTitle) => {
  const requestOptions = {
    url: GET_RECOMMENDED_JOBS,
    method: 'POST',
    // token,
    body: { jobTitle },
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getRecommendedSkillTitles = async (skillTitle) => {
  const requestOptions = {
    url: GET_RECOMMENDED_SKILLS,
    method: 'POST',
    // token,
    body: { skillTitle },
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const editCompanyCredits = async ({ id, credits }) => {
  const requestOptions = {
    url: EDIT_COMPANY_CREDITS,
    method: 'POST',
    body: { id, credits },
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    // if (err.response.data) {
    //   response = err.response.data
    // }
    console.log(err)
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const changeUserInfoCall = async (data) => {
  const requestOptions = {
    url: CHANGE_USER_INFO(data._id),
    method: 'POST',
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const changeUserPasswordCall = async ({
  _id,
  oldPassword,
  newPassword,
  repeatNewPassword,
}) => {
  const body = { oldPassword, newPassword, repeatNewPassword }
  const requestOptions = {
    url: CHANGE_USER_PASSWORD(_id),
    method: 'POST',
    body,
  }
  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}


export const createCVCall = async (cv) => {
  const requestOptions = {
    url: CREATE_CV,
    method: 'POST',
    body: cv,
  }
  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getCvForModal = async (cvID) => {
  const requestOptions = {
    url: GET_CV_FOR_MODAL(cvID),
    method: 'GET'
  }
  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const deleteAccountRequest = async () => {
  const requestOptions = {
    url: DELETE_ACCOUNT_REQUEST,
    method: 'GET',
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const createPaymentCall = async (data) => {
  const requestOptions = {
    url: CREATE_PAYMENT_REQUEST,
    method: 'POST',
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getAllPackages = async () => {
  const requestOptions = {
    url: GET_ALL_PACKAGES,
    method: 'GET',
    // token,
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}