import { all, fork } from 'redux-saga/effects'
import { watchAuthSaga } from '../features/loginComponent/authSaga';
import {watchEmployeesSaga} from './../features/employeeCardComponent/employeeSaga';
import {watchSearchSaga} from './../features/sideBar/searchSaga';
import { watchProfileSaga } from '../features/profilePage/profileSaga'

export default function* rootSaga() {
  yield all([
    watchAuthSaga,
    watchEmployeesSaga,
    watchSearchSaga,
    watchProfileSaga
    // more sagas from different files
  ].map(fork));
}
