import React from 'react';
import { Box, Card, Stack, Divider, Grid, Chip, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import EuroIcon from '@mui/icons-material/Euro';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import { authSliceSelector } from '../loginComponent/authSlice';

export default function CardInformation(props) {
    const { _id, title, yearsOfExperience, primarySkills, start, salary, location, workModel, level, contractType } = props
    const { verified } = useSelector(authSliceSelector)
    const intl = useIntl();

    const SkillsGrid = () => {
        const visibleSkills = primarySkills.slice(0, 10);

        return (
            <><Grid container spacing={1} mt={1} alignItems="center">
                {visibleSkills.map((skill) => (
                    <Grid item key={skill}>
                        <Tooltip title={skill.split(' ').length > 2 ? skill : ''} arrow>
                            <Chip
                                label={<Typography variant="body2" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {skill.split(' ').slice(0, 2).join(' ')} {skill.split(' ').length > 2 ? '...' : ''}
                                </Typography>}
                                sx={{ bgcolor: 'primary.main', height: 'auto', padding: 0.3, opacity: 0.9 }} />
                        </Tooltip>
                    </Grid>
                ))}
            </Grid><Typography>{primarySkills.length > 10 ? '...more' : ''}</Typography></>
        );
    };

    const breakString = (text, maxLength) => {
        const words = text.split(/\s+/); // Split by spaces or hyphens
        let lines = [];
        let currentLine = '';

        words.forEach((word) => {
            if (currentLine.length + word.length <= maxLength) {
                currentLine += `${word} `;
            } else {
                lines.push(currentLine.trim());
                currentLine = `${word} `;
            }
        });

        if (currentLine.trim()) {
            lines.push(currentLine.trim());
        }

        return lines.join('\n');
    };


    return (

        <Card key={_id} sx={{ position: 'relative', borderRadius: 1, borderColor: 'lightgrey', borderWidth: 0.5, borderStyle: 'solid', height: 290, width: 370, }}>
            <Stack direction={'row'} height={'inherit'}>
                <Box p={1.5} sx={{ width: '75%' }} >
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box >
                            <Typography variant='h6' display={'flex'} alignItems={'center'} color={'primary.dark'} sx={{ wordBreak: 'break-word' }}>
                                {breakString(title, 14)}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack direction='row' justifyContent={'space-between'} mb={1}>
                        <Typography variant='body2' fontStyle={'italic'}>
                            <FormattedMessage id='professionalLevel' />: {_.capitalize(level)}
                        </Typography>
                        <Typography variant='body2' fontStyle={'italic'}>
                            <FormattedMessage id='contract' />: {contractType ? <FormattedMessage id={contractType} /> : "-"}
                        </Typography>
                    </Stack>
                    <Divider width={230} />
                    <SkillsGrid />
                </Box>
                <Box p={1.5} width={'25%'} bgcolor={'whitesmoke'} >
                    <Stack flexDirection={'column'} height={'100%'} justifyContent={'space-between'} color={'primary.dark'} >
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                            <WorkHistoryIcon />
                            <Typography variant='body2' mb={1}>{
                                yearsOfExperience === 0
                                    ? '-'
                                    : yearsOfExperience + " " + intl.formatMessage({ id: 'years' })
                            }
                            </Typography>
                        </Box>
                        {verified &&
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                                <EuroIcon />
                                <Typography variant='body2' mb={1}>
                                    {
                                        salary === 0 ? '-' : salary?.toLocaleString().replace(',', '.') + intl.formatMessage({ id: 'perYear' })
                                    }
                                </Typography>
                            </Box>
                        }
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                            <CoPresentIcon />
                            <Typography variant='body2' mb={1}>{_.capitalize(workModel)}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                            <LocationOnIcon />
                            <Typography variant='body2' mb={1}>{location.areaOfApplication}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                            <EventAvailableIcon />
                            <Typography variant='body2' mb={1}>{(start === 0 || start === 1) ? <FormattedMessage id='immediately' /> : start + " " + intl.formatMessage({ id: 'weeks' })}</Typography>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Card>
    );
}