import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const GenericDialog = ({ open, onClose, title, content, buttonTexts }) => {
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{<FormattedMessage id={title} />}</DialogTitle>
      <DialogContent>
        <Typography>{<FormattedMessage id={content} />}</Typography>
      </DialogContent>
      <DialogActions>
        {buttonTexts.map((text, index) => (
          <Button key={index} onClick={() => onClose(index + 1)}>
            {<FormattedMessage id={text} />}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default GenericDialog;
