import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import employeeReducer from '../features/employeeCardComponent/employeeSlice'
import searchReducer from '../features/sideBar/searchSlice'
import myProfileReducer from '../features/profilePage/profileSlice'
import authReducer from '../features/loginComponent/authSlice';
import globalReducer from './slice';
import rootSaga from './saga';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the store middlewares array, to include saga middleware
const middleware = [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware];

const devMode = process.env.NODE_ENV === 'development';

if (devMode) {
  // If in devMode, add redux logging
  middleware.push(logger);
}

// Include all reducers to store
const reducer = combineReducers({
  globalState: globalReducer,
  authState: authReducer,
  employeeState: employeeReducer,
  searchState: searchReducer,
  profileState: myProfileReducer,
});

export const store = configureStore({
  reducer,
  devTools: devMode,
  middleware
});

// Start sagas
sagaMiddleware.run(rootSaga);