import { takeLatest, call, put } from 'redux-saga/effects'
import { signUp, signIn, setSignInUser, resetPassword, forgotPassword } from './authSlice'
import { authWithToken, signInCall, signUpCall, resetPasswordCall, forgotPasswordCall } from '../../utils/apiCalls'
import { toast } from 'react-toastify'
import { setProfileInfo } from '../profilePage/profileSlice'
import { setLoading } from '../../app/slice'

function* signInSaga(action) {
  try {
    yield put(setLoading(true))
    let res;
    if (action.payload.authType === 'credentials') {
      //Sign in with email and password
      res = yield call(signInCall, action.payload.user)
    } else {
      //Sign in with jwt 
      res = yield call(authWithToken)
    }
    if (!res.error) {
      localStorage.setItem('userId', res._id)
      yield put(setProfileInfo(res))
      yield put(setSignInUser(res))
      action.payload.navigate(action.payload.navTo)
    } else {
      if (action.payload.authType === 'credentials') {
        toast.error(res.message)
      }
    }
  } catch (err) {
    console.error('New error', err)
    toast.error(err.message)
  }
  yield put(setLoading(false))
}

function* signUpSaga(action) {
  try {
    yield put(setLoading(true))
    const res = yield call(signUpCall, action.payload.user)

    if (res.status === 201) {
      action.payload.navigate('/sign-in')
    } else {
      toast.error(res.message)
    }
  } catch (err) {
    console.error('New error', err)
    toast.error(err.message)
  }
  yield put(setLoading(false))
}

function* forgotPasswordSaga(action) {
  try {
    yield put(setLoading(true))
    const res = yield call(forgotPasswordCall, action.payload)

    if (res.status === 200) {
      toast.success(res.message)
    } else {
      toast.error(res.message)
    }
  } catch (err) {
    console.error('New error', err)
    toast.error(err.message)
  }
  yield put(setLoading(false))
}

function* resetPasswordSaga(action) {
  try {
    yield put(setLoading(true))
    const res = yield call(resetPasswordCall, action.payload.data)

    if (res.status === 200) {
      toast.success(res.message)
      action.payload.navigate('/sign-in')
    } else {
      toast.error(res.message)
    }
  } catch (err) {
    console.error('New error', err)
    toast.error(err.message)
  }
  yield put(setLoading(false))
}

export function* watchAuthSaga() {
  yield takeLatest(signIn.type, signInSaga)
  yield takeLatest(signUp.type, signUpSaga)
  yield takeLatest(forgotPassword.type, forgotPasswordSaga)
  yield takeLatest(resetPassword.type, resetPasswordSaga)
}
