import { Box, Button, Stack, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

export const PaymentCancel = () => {
    const navigate = useNavigate()

    return (
        <Box sx={{
            marginTop: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Box
                sx={{
                    boxShadow: 3,
                    gap: 2,
                    py: 6,
                    px: 10,
                    borderRadius: '25px',
                    border: '1px solid #dddddd',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CancelRoundedIcon color={'error'} sx={{ fontSize: 60 }} />
                <Typography fontSize={22} pb={2}><FormattedMessage id='paymentCancel' /></Typography>
                <Stack direction={'row'} gap={5}>
                    <Button
                        size='medium'
                        variant='outlined'
                        onClick={() => navigate('/profile')}
                    >
                        OK
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
