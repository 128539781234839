import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { searchSliceSelector, setStartDate } from '../searchSlice';
import { debounce, toNumber } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl'
import { Typography } from '@mui/material';

export default function StartFilters() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { filter: { startDate: selectedFilters } } = useSelector(searchSliceSelector);
    const [localMin, setLocalMin] = useState(selectedFilters.min || 0);
    const [localMax, setLocalMax] = useState(selectedFilters.max || 0);

    const [errorMessage, setErrorMessage] = useState('');

    // Sync local state with Redux store
    useEffect(() => {
        setLocalMin(selectedFilters.min || 0);
        setLocalMax(selectedFilters.max || 0);
    }, [selectedFilters.min, selectedFilters.max]);

     useEffect(() => {
        if (localMin > localMax) {
            setErrorMessage(intl.formatMessage({ id: 'minLessThanMax' }));
        } else {
            setErrorMessage('');
        }
    }, [localMin, localMax, intl]);

     // Debounce setup
     useEffect(() => {
        const debouncedUpdate = debounce(() => {
            dispatch(setStartDate({ min: localMin, max: localMax }));
        }, 1000);

        // Only debounce updates if the values are different to avoid unnecessary dispatches
        if ((selectedFilters.min !== localMin || selectedFilters.max !== localMax) && (localMin <= localMax)) {
            debouncedUpdate();
        }

        // Cleanup function to cancel the debounced call if component unmounts
        return () => debouncedUpdate.cancel();
    }, [localMin, localMax, dispatch, selectedFilters.min, selectedFilters.max]);


    const handleMinSalaryChange = (event) => {
        const value = Number(event.target.value);
        setLocalMin(value >= 0 ? value : localMin); // Prevent setting NaN or negative values
    };

    const handleMaxSalaryChange = (event) => {
        const value = Number(event.target.value);
        setLocalMax(value >= 0 ? value : localMax);
    };

    return (
        <Stack margin={2}>
            <Stack
                direction={'row'}
                component="form"
                spacing={1}
                noValidate
                autoComplete="off"
            >
                <TextField
                    sx={{ bgcolor: 'whitesmoke', borderRadius: '3px' }}
                    label={<FormattedMessage id='min' />}
                    variant="filled"
                    value={localMin}
                    onChange={handleMinSalaryChange} />
                <TextField
                    sx={{ bgcolor: 'whitesmoke', borderRadius: '3px' }}
                    label={<FormattedMessage id='max' />}
                    variant="filled"
                    value={localMax}
                    onChange={handleMaxSalaryChange} />
            </Stack>
            <Typography color={'error'} variant='body2' mt={1} sx={{ whiteSpace: 'pre-line' }}>{errorMessage}</Typography>
        </Stack>
    )
}
