import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxContainer, CheckboxColor } from './checkboxStyles';
import { useDispatch, useSelector } from 'react-redux';
import { searchSliceSelector, setWorkModel } from '../searchSlice';
import _ from 'lodash'

export default function WorkModelFilters() {
    const dispatch = useDispatch()
    const selectedModels = useSelector(searchSliceSelector).filter.workModel
    const availableModels = ['remote', 'hybrid', 'on-site']


    const handleClick = (roleClicked) => {
        const isBoxChecked = selectedModels.includes(roleClicked)

        if (isBoxChecked) {
            const newRolesArray = [...selectedModels].filter(selectedRole => selectedRole !== roleClicked)
            dispatch(setWorkModel(newRolesArray))
            return
        }
        const newRolesArray = [...selectedModels];
        newRolesArray.push(roleClicked);
        dispatch(setWorkModel(newRolesArray))
    }

    const isBoxChecked = (role) => {
        return selectedModels.includes(role)
    }

    function createCheckboxes() {
        return availableModels?.map(role => (<FormControlLabel key={role} control={<Checkbox sx={CheckboxColor} checked={isBoxChecked(role)} />} sx={{ color: '#FAF9F6' }} label={_.capitalize(role)} onChange={() => handleClick(role)} />))
    }

    return (
        <FormGroup sx={CheckboxContainer}>
            {createCheckboxes()}
        </FormGroup>
    )
}
