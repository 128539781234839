import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { searchSliceSelector, setLevel } from '../searchSlice';
import { CheckboxContainer, CheckboxColor } from './checkboxStyles';
import _ from 'lodash'

export default function LevelFilters() {
    const dispatch = useDispatch()
    const selectedRoles = useSelector(searchSliceSelector).filter.level
    const availableRoles = ['junior', 'mid', 'senior', 'lead']

    const handleClick = (roleClicked) => {
        const isBoxChecked = selectedRoles.includes(roleClicked)

        if (isBoxChecked) {
            const newRolesArray = [...selectedRoles].filter(selectedRole => selectedRole !== roleClicked)
            dispatch(setLevel(newRolesArray))
            return
        }
        const newRolesArray = [...selectedRoles];
        newRolesArray.push(roleClicked);
        dispatch(setLevel(newRolesArray))
    }

    const isBoxChecked = (role) => {
        return selectedRoles.includes(role)
    }

    function createCheckboxes() {
        return availableRoles?.map(role => (<FormControlLabel key={role} control={<Checkbox sx={CheckboxColor} checked={isBoxChecked(role)} />} sx={{color: '#FAF9F6'}} label={_.capitalize(role)} onChange={() => handleClick(role)} />))
    }

    return (
        <FormGroup sx={CheckboxContainer}>
            {createCheckboxes()}
        </FormGroup>
    )
}
