import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxContainer, CheckboxColor } from './checkboxStyles';
import { useDispatch, useSelector } from 'react-redux';
import { searchSliceSelector, setContractTypes } from '../searchSlice';
import { useIntl } from 'react-intl';

export default function ContractTypeFilters() {
    const dispatch = useDispatch()
    const intl = useIntl();
    const selectedContracts = useSelector(searchSliceSelector).filter.contractType
    const availableContracts = ['employment']

    const handleClick = (contractClicked) => {

        const isBoxChecked = selectedContracts.includes(contractClicked)

        if (isBoxChecked) {
            const newContractsArray = [...selectedContracts].filter(selectedRole => selectedRole !== contractClicked)
            dispatch(setContractTypes(newContractsArray))
            return
        }
        const newContractsArray = [...selectedContracts];
        newContractsArray.push(contractClicked);
        dispatch(setContractTypes(newContractsArray))
    }

    const isBoxChecked = (contract) => {
        return selectedContracts.includes(contract)
    }

    function createCheckboxes() {
        return availableContracts?.map(contract => (<FormControlLabel
            key={contract}
            control={<Checkbox
                sx={CheckboxColor}
                checked={isBoxChecked(contract)} />}
            sx={{ color: '#FAF9F6' }}
            label={intl.formatMessage({ id: contract })}
            onChange={() => handleClick(contract)} />))
    }

    return (
        <FormGroup sx={CheckboxContainer}>
            {createCheckboxes()}
        </FormGroup>
    )
}
