import { useState } from 'react'
import { Box, Stack } from '@mui/material'
import Information from './components/Information'
import Company from './components/Company'
import CreditsHistory from './components/CreditsHistory'

export default function Profile() {
  const [page, setPage] = useState('Information')

  return (
    <Box minHeight={600}>
      <Stack direction='row' sx={{ marginTop: '170px' }}>
        <Company setPage={setPage} />
        <Box sx={{ width: '65%' }}>
          {page === 'Information' && <Information />}
          {page === 'Purchases' && <CreditsHistory />}
        </Box>
      </Stack>
    </Box>
  )
}
