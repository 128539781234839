import { createSlice } from '@reduxjs/toolkit'

const AUTH_SLICE = 'AUTH_SLICE'

const initialState = {
  authenticated: false,
  role: '',
  verified: false,
  language: 'de'
};

export const authSlice = createSlice({
  name: AUTH_SLICE,
  initialState,
  reducers: {
    setSignInUser: (state, action) => {
      state.authenticated = true
      state.role = action.payload.role
      state.verified = action.payload.verified
      state.licenseStart = action.payload.licenseStart
      state.licenseEnd = action.payload.licenseEnd
    },
    setSignOutUser: (state) => {
      state.authenticated = false
      state.verified = false
      state.role = ''
      state.licenseStart = ''
      state.licenseEnd = ''
      localStorage.removeItem('userId')
    },
    setSelectedLanguage: (state, action) => {
      state.language = action.payload
    },
    signIn: () => {},
    signUp: () => {},
    forgotPassword: () => {},
    resetPassword: () => {}
  }
})

export const {
  signIn,
  signUp,
  setSignInUser,
  setSignOutUser,
  setSelectedLanguage,
  forgotPassword,
  resetPassword
} = authSlice.actions;

export const authSliceSelector = (state) => state.authState

export default authSlice.reducer
