import MainComponent from "./features/MainComponent";
import Header from "./features/headerComponent/header";
import { ThemeProvider } from "@mui/material/styles";
import theme from './style/style.js'
import Footer from "./features/footerComponent/footer";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import SignIn from "./features/loginComponent/signIn";
import SignUp from "./features/loginComponent/signUp";
import Profile from "./features/profilePage/Profile";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderOverlay from "./features/loadingComponent/loaderOverlay";
import { useSelector } from "react-redux";
import { globalSliceSelector } from "./app/slice";
import { AuthGuard } from './routing/ProtectedComponent';
import { IntlProvider } from 'react-intl'
import messages from './translation/messages.json'
import { authSliceSelector, signIn } from "./features/loginComponent/authSlice";
import { Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './style/style.css';
import ResetPassword from "./features/loginComponent/resetPassword.js";
import { PaymentSuccess } from "./features/paymentSuccess/PaymentSuccess.jsx";
import { PaymentCancel } from "./features/paymentCancel/PaymentCancel.jsx";


function App() {
  const { isLoading } = useSelector(globalSliceSelector);
  const { language } = useSelector(authSliceSelector)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const userID = localStorage.getItem('userId')

    //Persist user in redux after refresh and navigate to the page he was before
    if (userID) {
      dispatch(signIn({ authType: 'token', navigate, navTo: location.pathname }));
    }
  }, [dispatch]);

  return (
    <IntlProvider messages={(messages)[language]} locale={language} defaultLocale="en">
      <ThemeProvider theme={theme}>
        {isLoading && <LoaderOverlay showOverlay={true} />}
        <div className="app-container">
          <Container maxWidth={false} className='content'>
            <Grid container>
              <Grid item xs={12}>
                <div className='contentPaper'>
                  <Header />
                  <Routes>
                    <Route path='/' element={
                      <MainComponent />
                    } />
                    <Route path='/sign-in' element={<SignIn />} />
                    <Route path='/sign-up' element={<SignUp />} />
                    <Route path='/reset-password/:token' element={<ResetPassword />} />
                    <Route path='/profile' element={<AuthGuard><Profile /></AuthGuard>} />
                    <Route path='/payment-success/:credits' element={<AuthGuard><PaymentSuccess /></AuthGuard>} />
                    <Route path='/payment-cancel' element={<AuthGuard><PaymentCancel /></AuthGuard>} />
                  </Routes>
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="footer">
            <Footer />
          </div>
        </div>
        <ToastContainer />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;