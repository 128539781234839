import { Typography, Stack, Button, Box } from '@mui/material'
import Credits from './Credits'
import { useSelector } from 'react-redux'
import { profileSliceSelector } from '../profileSlice'

export default function Company(props) {
  const { setPage } = props
  const { companyName } = useSelector(profileSliceSelector)

  return (
    <Stack
      direction='column'
      display={'flex'}
      alignItems={'center'}
      alignContent={'center'}
      textAlign={'center'}
      sx={{ borderRight: 'solid 1px grey', mr: 10, width: '20%' }}
    >
      <Box mb={30}>
        <Typography variant='h4' fontWeight='bold' color="primary.dark">{companyName}</Typography>
        <Button
          sx={{ height: 30, width: 150, m: 2 }}
          variant='outlined'
          size='medium'
          onClick={() => setPage('Information')}
        >
          Information
        </Button>
      </Box>
      <Credits />
    </Stack>
  )
}
