import { Box, Button, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { authSliceSelector } from '../loginComponent/authSlice'

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0.5px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

export default function EmployeeNotAvailableModal(props) {
    const { showModal } = props
    const { language } = useSelector(authSliceSelector)

    const handleClose = () => {
        props.close()
    }

    const LinkMaisourcer = () => <Link>moin@maisourcer.de</Link>

    const germanTextWithLink = () => (
        <Typography variant='h6'>Der Kandidat steht nicht mehr zur Verfügung. Für mehr Informationen bitte an {LinkMaisourcer()} wenden.</Typography>
    )

    const englishTextWithLink = () => (
        <Typography variant='h6'>The candidate is no longer available. For more information please contact {LinkMaisourcer()}.</Typography>
    )

    const modalText = language === 'de' ? germanTextWithLink() : englishTextWithLink()

    return (
        <div>
            <Modal
                open={showModal}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}
                    >
                        <Typography variant='h6'>
                            {modalText}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                direction: 'row',
                                justifyContent: 'center',
                                width: '100%',
                                mt: 2,
                            }}
                        >
                            <Button
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                                onClick={props.close}
                            >
                                OK
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
