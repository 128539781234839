import {
  Box, Modal, Typography, Stack, Grid, Divider, Chip, List, ListItem, ListItemText, ListSubheader
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectEmployee,
  setEmpolyeeReducer,
} from '../employeeCardComponent/employeeSlice'
import React, { useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { toUpper } from 'lodash'
import EuroIcon from '@mui/icons-material/Euro'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import FlightIcon from '@mui/icons-material/Flight'
import EditNoteIcon from '@mui/icons-material/EditNote';
import _ from 'lodash'
import moment from 'moment'
import { authSliceSelector } from '../loginComponent/authSlice'
import { FormattedMessage, useIntl } from 'react-intl'
import './../../style/modalPrint.css';
import LicenceModal from './LicenseModal'

const skillsGrid = (skills, isSecondary) => {
  return (
    <Grid container spacing={1} mt={2} alignItems='center'>
      {skills?.map((skill) => (
        <Grid margin={0.5} key={skill}>
          <Chip
            label={<Typography variant="body2" sx={{ whiteSpace: 'normal', }}>
              {skill}
            </Typography>}
            sx={{
              height: 'auto',
              padding: 0.3,
              bgcolor: isSecondary ? 'secondary.light' : 'primary.main',
              opacity: 0.9,
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default function EmployeeModal(props) {
  const [showLicenceModal, setShowLicenceModal] = useState(false)
  const { showModal } = props
  const { employeeInfo } = useSelector(selectEmployee)
  const { location, jobTitle, salaryRatePerHour, level, primarySkills,
    secondarySkills, yearsOfExperience, languages, willToTravel, proExperience, startInWeeks, teaser, workModel, contractType } = employeeInfo

  const { verified } = useSelector(authSliceSelector)
  const dispatch = useDispatch()
  const intl = useIntl();

  const handleEmployeeModalClose = () => {
    props.close()
    dispatch(
      setEmpolyeeReducer({
        employeeInfo: { firstname: '' },
      })
    )
  }

  return (
    <>
      <Modal
        open={showModal}
        onClose={handleEmployeeModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Grid container className='style-modal' >
          <Stack direction='column' alignItems="center" sx={{ backgroundColor: 'primary.dark', width: '28%' }} justifyContent="space-between">
            <Divider
              sx={{
                '&::before, &::after': {
                  borderColor: 'lightgrey',
                  borderWidth: '1px',
                  width: '55px',
                },
                color: 'lightgrey',
                marginTop: '10px',
              }}
            >
              <Chip label={<FormattedMessage id='primarySkills' />} sx={{ color: 'lightgrey', bgcolor: 'rgb(195, 196, 199, 0.2)' }}></Chip>
            </Divider>
            <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', gap: 1, mt: 1, mb: 2, ml: 3, mr: 3 }}>
              {skillsGrid(primarySkills, false)}
            </Box>
            <Divider
              sx={{
                '&::before, &::after': {
                  borderColor: 'lightgrey',
                  borderWidth: '1px',
                  width: '30px',
                },
                color: 'lightgrey',
              }}
            >
              <Chip label={<FormattedMessage id='secondarySkills' />} sx={{ color: 'lightgrey', bgcolor: 'rgb(195, 196, 199, 0.2)' }}></Chip>
            </Divider>
            <Box
              sx={{
                display: 'inline-flex',
                flexWrap: 'wrap',
                gap: 1,
                margin: 3,
              }}
            >
              {skillsGrid(secondarySkills, true)}
            </Box>
          </Stack>
          <Box
            display={'flex'}
            justifyContent={'center'}
            bgcolor={'primary.main'}
            width='12%'
            color={'primary.dark'}
          >
            <Stack
              direction='column'
              display={'flex'}
              textAlign={'center'}
              justifyContent={'space-between'}
              alignItems={'center'}
              m={1}
              pt={1}
            >
              <WorkHistoryIcon />
              <Typography mb={1}>{yearsOfExperience === 0 ? '-' : yearsOfExperience + " " + intl.formatMessage({ id: 'years' })}</Typography>
              {verified &&
                <>
                  <EuroIcon />
                  <Typography mb={1}>{salaryRatePerHour === 0 ? '-' : salaryRatePerHour?.toLocaleString().replace(',', '.') + intl.formatMessage({ id: 'perYear' })}</Typography>
                </>
              }
              <CoPresentIcon />
              <Typography mb={1}>{_.capitalize(workModel)}</Typography>
              <LeaderboardIcon />
              <Typography mb={1}>{_.capitalize(level)}</Typography>
              <EditNoteIcon sx={{ fontSize: 35 }} />
              <Typography mb={1}>
                {contractType ? <FormattedMessage id={contractType} /> : '-'}
              </Typography>
              <EventAvailableIcon />
              <Typography mb={1}>{(startInWeeks === 0 || startInWeeks === 1) ? <FormattedMessage id='immediately' /> : startInWeeks + " " + intl.formatMessage({ id: 'weeks' })}</Typography>
              <FlightIcon />
              <Typography mb={1}>
                {willToTravel ? <FormattedMessage id='willToTravel' /> : <FormattedMessage id='noTravel' />}
              </Typography>
            </Stack>
          </Box>
          <Stack
            direction='column'
            justifyContent='space-between'
            sx={{ backgroundColor: 'secondary.main', width: '60%' }}
          >
            <Box ml={2} mr={2} mb={2} mt={2}>
              <Divider>
                <Chip label={toUpper(jobTitle)} />
              </Divider>
            </Box>
            <Stack direction='row' ml={2} mr={2} justifyContent='space-between'>
              <Stack direction='column'>
                <Typography
                  variant='overline'
                  display='block'
                  fontWeight={'bold'}
                >
                  <FormattedMessage id='location' />
                </Typography>
                <Typography variant='overline' display='block'>
                  {location?.areaOfApplication}
                </Typography>
              </Stack>
              <Stack direction='column' pl={1}>
                <Typography
                  variant='overline'
                  display='block'
                  fontWeight={'bold'}
                >
                  <FormattedMessage id='languages' />
                </Typography>
                <Typography variant='overline' display='block'>
                  {languages?.map(function (item, index) {
                    return (
                      <span key={`language_${index}`}>
                        {(index ? ', ' : '') + item}
                      </span>
                    )
                  })}
                </Typography>
              </Stack>
              <Stack direction='column'>
                <Typography
                  variant='overline'
                  display='block'
                  fontWeight={'bold'}
                >
                  <FormattedMessage id='companySize' />
                </Typography>
                <Typography variant='overline' display='block'>
                  {(teaser?.companySize === '-' || teaser?.companySize === 'flexible') ? intl.formatMessage({ id: 'flexible' }) : teaser?.companySize}
                </Typography>
              </Stack>
            </Stack>
            <Box className='style-modal-box'>
              <List dense={true}
                subheader={
                  <ListSubheader style={{ backgroundColor: 'transparent', position: 'relative', paddingLeft: 0 }}>
                    {proExperience?.length > 0 ? <FormattedMessage id='proExperience' /> : ''
                    }</ListSubheader>}>
                {proExperience?.map((step, index) => (
                  <Stack direction={'row'}>
                    <Chip label={index + 1} sx={{ mt: 1 }}></Chip>
                    <ListItem>
                      <Stack>
                        <ListItemText
                          style={{ padding: 0, margin: 0 }}
                          sx={{
                            '.MuiListItemText-primary': {
                              fontSize: 17,
                              fontWeight: 'bold',
                            }
                          }}
                          primary={step.title + ' - ' + step.companyType}
                          secondary={moment(step.start).format('MM.YYYY') + '-' + (step.end !== null ? moment(step.end).format('MM.YYYY') : intl.formatMessage({ id: 'current' }))} />
                        <Typography variant='body2'>{step.description}</Typography>
                      </Stack>
                    </ListItem>
                  </Stack>
                ))}
              </List>
            </Box>
            {teaser?.description && <Typography m={3} fontStyle={'italic'} variant='body2'>"{teaser?.description}"</Typography>}
          </Stack>
        </Grid>
      </Modal>

      <LicenceModal open={showLicenceModal} close={() => setShowLicenceModal(false)} />
    </>
  )
}