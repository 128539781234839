import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { Container, Modal, Stack } from '@mui/material';
import GenericDialog from './GenericDialog';
import { useState } from 'react';
import { deleteAccountRequest } from './../../utils/apiCalls'
import { useDispatch } from 'react-redux';
import { setLoading } from '../../app/slice';

const style = {
    position: 'absolute',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

export default function DeleteAccountModal({ open, close }) {
    const dispatch = useDispatch()
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('')

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        dispatch(setLoading(true))
        const request = await deleteAccountRequest()
        dispatch(setLoading(false))

        if (request.statusCode === 200) {
            setDialogContent('deleteAccount.text.confirmation')
            setOpenDialog(true);

        } else {
            setDialogContent('deleteAccount.text.error')
        }
        close();
    };

    return (
        <>
            <Modal
                open={open}
                onClose={close}
            >
                <Box sx={style}>
                    <Container component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <Typography variant="body1" align={'center'}>
                            <FormattedMessage id='deleteAccount.text.modal' />
                        </Typography>
                        <Stack direction={'row'} spacing={4} mt={3}>
                            <Button variant="contained" color="success" fullWidth onClick={close}>
                                <FormattedMessage id="cancel" />
                            </Button>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                <FormattedMessage id="yes" />
                            </Button>
                        </Stack>
                    </Container>
                </Box>
            </Modal>

            <GenericDialog
                open={openDialog}
                onClose={handleCloseDialog}
                title="deleteAccount"
                content={dialogContent}
                buttonTexts={['OK']}
            />
        </>
    );
}