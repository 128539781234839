import { useEffect, useRef } from "react";

/*
This custom hook checks if something other than the card was clicked or the modal that opened from the card
In order to return the cb and remove the shadow box from the selected card
If you want this to work more generic change the modalElement (line 13) - better if you pass it as a param
*/
export default function useClickAway(cb) {
  const ref = useRef(null);

  const onEventHandler = (e) => {
    const element = ref.current;
    const modalElement = e.target.closest('[role="presentation"]'); // material ui modals have this role

    if (element && !element.contains(e.target) && modalElement === null) {
      cb(e);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onEventHandler);
    document.addEventListener("touchstart", onEventHandler);

    return () => {
      document.removeEventListener("mousedown", onEventHandler);
      document.removeEventListener("touchstart", onEventHandler);
    };
  }, []);

  return ref;
}
