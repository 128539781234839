import React, { useState } from 'react'
import { Box } from '@mui/material'
import CardInformation from './CardContent'
import { selectEmployee, setEmpolyeeReducer, setSelectedCard } from './employeeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { authSliceSelector } from '../loginComponent/authSlice'
import { setShowSignInRegisterModal } from '../profilePage/profileSlice'
import useClickAway from '../../customHooks/useClickAway'
import { getCvForModal } from '../../utils/apiCalls'
import EmployeeModal from '../modals/EmployeeModal'
import EmployeeNotAvailableModal from '../modals/EmployeeNotAvailableModal'
import { useRef } from 'react'
import { useEffect } from 'react'

function Card(props) {
  const [showModal, setShowModal] = useState(false)
  const [showNotAvailableModal, setShowNotAvailableModal] = useState(false)
  const { authenticated } = useSelector(authSliceSelector)
  const { selectedCard } = useSelector(selectEmployee)
  const ref = useRef(null);
  const dispatch = useDispatch()

  const {
    _id,
    title,
    yearsOfExperience,
    primarySkills,
    level,
    salary,
    location,
    workModel,
    start,
    saved,
    contractType
   } = props

  useEffect(() => {
    if (selectedCard === _id) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedCard, _id]);

  const clickAwayRef = useClickAway(() => {
    dispatch(setSelectedCard(''))

  });

  const handleCardClick = async (id) => {
    dispatch(setSelectedCard(id))

    if (!authenticated) {
      dispatch(setShowSignInRegisterModal(true))
      return
    }

    const foundCandidate = await getCvForModal(_id)

    if (foundCandidate.data.length === 0) {
      openNotAvailableModal()
      return
    }

    if (foundCandidate && foundCandidate.data.length > 0) {
      setShowModal(true)
      dispatch(
        setEmpolyeeReducer({
          employeeInfo: { ...foundCandidate.data[0] },
        })
      )
    }
  }

  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)
  const openNotAvailableModal = () => setShowNotAvailableModal(true)
  const closeNotAvailableModal = () => setShowNotAvailableModal(false)

  return (
    <>
      <EmployeeModal showModal={showModal} open={openModal} close={closeModal} />
      <EmployeeNotAvailableModal showModal={showNotAvailableModal} open={openNotAvailableModal} close={closeNotAvailableModal} />
      <Box
        ref={(el) => {
          ref.current = el;
          clickAwayRef.current = el;
        }}
        key={'box' + _id}
        style={{ cursor: 'pointer', marginRight: 20, marginBottom: 20, boxShadow: (selectedCard === _id) ? '0 0 10px 3px rgba(235, 65, 91, 0.9)' : '' }}
        onClick={() => {
          handleCardClick(_id)
        }}
      >
        <CardInformation
          _id={_id}
          title={title}
          yearsOfExperience={yearsOfExperience}
          level={level}
          primarySkills={primarySkills}
          salary={salary}
          location={location}
          workModel={workModel}
          start={start}
          saved={saved}
          contractType={contractType}
        />
      </Box>
    </>
  )
}

export default Card
