import React, { useState, useEffect, useMemo } from "react";
import { Grid, Chip, Stack, Box } from "@mui/material";
import SearchField from "./searchField";
import { useDispatch, useSelector } from "react-redux";
import {
    addJobTitle, editJobTitle, removeJobTitle, addSkillTitle, editSkillTitle,
    removeSkillTitle, fetchRecommendedLabels, searchSliceSelector, setRecommendedJobs, addRecommendedJob,
    removeRecommendedJob, setRecommendedSkills, addRecommendedSkill, removeRecommendedSkill, setPage
} from '../sideBar/searchSlice';
import LoaderOverlay from './../loadingComponent/loaderOverlay';
import { debounce } from "lodash";

export default function SearchLayout() {
    const { filter, recommendedJobs, recommendedSkills } = useSelector(searchSliceSelector)
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        const generatedLabels = document.querySelector('.generatedLabels');
        const selectedLabels = document.querySelector('.selectedLabels');
        const field = document.querySelector('#searchfield');

        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                generatedLabels.style.display = 'none';
                field.style.display = 'none';

                selectedLabels.style.top = '70%';
                selectedLabels.style.maxWidth = '90%';
                selectedLabels.style.maxHeight = '40px';
                selectedLabels.style.overflowY = 'auto';
                selectedLabels.style.overflowX = 'hidden';
            } else {
                generatedLabels.style.display = 'flex';
                field.style.display = 'flex';

                selectedLabels.style.zIndex = 1000;
                selectedLabels.style.top = '70%';
                selectedLabels.style.maxWidth = '70%';
                selectedLabels.style.maxHeight = '80px';
            }
        })
    }, [])

    const debouncedSearch =(value) => {
        dispatch(fetchRecommendedLabels(value));
    };

    const onSearchChange = async (value) => {
        setSearchValue(value);
        setLoading(true);

        if (value.length >= 1) {
            debouncedMyFunction(value);
        } else {
            dispatch(setRecommendedJobs([]));
            dispatch(setRecommendedSkills([]));
            setLoading(false);
        }
    };

    const debouncedMyFunction = useMemo((value) => debounce(debouncedSearch, 1000), [])

    const handleLabelClick = async (e, title) => {
        if (title === 'jobTitle') {
            dispatch(addJobTitle(e.target.innerText))
            dispatch(removeRecommendedJob(e.target.innerText))
        } else {
            dispatch(addSkillTitle(e.target.innerText))
            dispatch(removeRecommendedSkill(e.target.innerText))
        }
    }

    const handleDeleteLabel = (value, title) => {
        const removeAction = title === 'jobTitle' ? removeJobTitle : removeSkillTitle;
        dispatch(removeAction(value));

        if (searchValue !== '' && value.toLowerCase().includes(searchValue.toLowerCase())) {
            const addAction = title === 'jobTitle' ? addRecommendedJob : addRecommendedSkill;
            dispatch(addAction(value));
        }
    }

    const handleClickOnSelectedLabel = (e, title) => {
        const value = e.target.innerText;
        title === 'jobTitle' ? dispatch(editJobTitle(value)) : dispatch(editSkillTitle(value))
    }

    const renderRecommendedLabels = (items, keyProp) => {
        return items?.map((item) => (
            <Grid margin={0.5} key={item[keyProp]}>
                <Chip
                    label={item[keyProp]}
                    variant="outlined"
                    color={keyProp === 'jobTitle' ? 'success' : 'primary'}
                    onClick={(e) => handleLabelClick(e, keyProp)}
                />
            </Grid>
        ));
    };

    const renderSelectedLabels = (items, keyProp) => {
        return items?.map((item) => (
            <Grid margin={0.5} key={item[keyProp]}>
                <Chip
                    label={item.selected ? item[keyProp] : <del>{item[keyProp]}</del>}
                    variant="outlined"
                    color={keyProp === 'jobTitle' ? 'success' : 'primary'}
                    onClick={(e) => handleClickOnSelectedLabel(e, keyProp)}
                    onDelete={() => handleDeleteLabel(item[keyProp], keyProp)}
                />
            </Grid>
        ));
    };

    return (
        <Stack justifyContent="center" alignItems="center" mt={8} ml={8}>
            <Box className='generatedLabels'
                sx={{
                    display: 'flex',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    maxWidth: '70%',
                    marginBottom: 25,
                    maxHeight: 80,
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar in WebKit browsers
                    },
                }}
            >
                {loading && <LoaderOverlay showOverlay={false} />}
                {renderRecommendedLabels(recommendedJobs, "jobTitle")}
                {renderRecommendedLabels(recommendedSkills, "skillTitle")}
            </Box>
            <SearchField searchValue={searchValue} onSearchChange={onSearchChange} />
            <Box top={'70%'} maxHeight={80} className='selectedLabels'
                sx={{
                    display: 'flex',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    maxWidth: '70%',
                    marginBottom: 25,
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar in WebKit browsers
                    },
                }}
            >
                {renderSelectedLabels(filter.jobTitle, "jobTitle")}
                {renderSelectedLabels(filter.skillTitle, "skillTitle")}
            </Box>
        </Stack >
    );
}