import { toast } from 'react-toastify'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  changeUserInfoCall,
  changeUserPasswordCall,
  createPaymentCall,
} from '../../utils/apiCalls'
import {
  buyCredits,
  changeUserInfo,
  changeUserPassword,
  setProfileInfo,
} from './profileSlice'
import { loadStripe } from '@stripe/stripe-js'
import { setSignOutUser } from '../loginComponent/authSlice'

function* changeUserInfoSaga(action) {
  try {
    const { closeModal, ...infoWithoutCloseFunction } = action.payload;
    const res = yield call(changeUserInfoCall, infoWithoutCloseFunction)

    if (!res.error) {
      yield put(setProfileInfo(res.data))
      toast.success('Successfully Updated Profile')
      action.payload.closeModal()
    } else {
      toast.error(res.message)
    }
  } catch (err) {
    console.error('New error', err)
  }
}

function* changeUserPasswordSaga(action) {
  try {
    const res = yield call(changeUserPasswordCall, action.payload)
    if (res.statusCode === 200) {
      toast.success(res.message)
      yield put(setSignOutUser())
    } else {
      toast.error(res.message)
    }
  } catch (err) {
    toast.error(JSON.stringify(err))
    console.error('New error', err)
  }
}

function* buyCreditsSaga(action) {
  // We need here our client Key that Stripe Provides
  // in the home page there is a Stripe Publishable key
  const stripe = yield call(loadStripe, process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  console.log(stripe);
  console.log(action.payload);

  const body = action.payload
  if (Object.values(body).some(val => !val)) return

  const response = yield call(createPaymentCall, body)

  // finally we can await for server response in the form of session, if the payment goes successfull
  // redirect user to Checkout and can store session id using the session variable
  yield call(stripe.redirectToCheckout, {
    sessionId: response.id
  })
}


export function* watchProfileSaga() {
  yield takeLatest(changeUserInfo.type, changeUserInfoSaga)
  yield takeLatest(changeUserPassword.type, changeUserPasswordSaga)
  yield takeLatest(buyCredits.type, buyCreditsSaga)
}
