import { Grid, Stack, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import Card from './Card'
import { filterEmployees, resetEmployeesReducer, selectEmployee } from './employeeSlice'
import SignInRegisterModal from '../modals/SignInRegisterModal'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import InfiniteScroll from "react-infinite-scroll-component";
import { searchSliceSelector, setPage } from '../sideBar/searchSlice'
import useOnDependencyChange from '../../customHooks/useOnDependencyChange'

export default function EmployeeList() {
  const [hasMore, setHasMore] = useState(true)
  const { employees, totalEmployees, totalFilteredEmployees } = useSelector(selectEmployee)
  const { filter, page } = useSelector(searchSliceSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!employees.length) {
      dispatch(filterEmployees())
    }
  }, [])

  const fetchMoreData = () => {
    dispatch(setPage(page + 1))
  };

  const resetEmployeesState = () => {
    dispatch(setPage(0))
    dispatch(resetEmployeesReducer())
  }

  useOnDependencyChange(() => {
    resetEmployeesState()
    dispatch(filterEmployees())
  }, [filter])

  useOnDependencyChange(() => {
    dispatch(filterEmployees())
  }, [page])

  // When the user scrolls(page changes) or when a filter has been set(totalEmployees changes)
  // We need to check if the hasMore value should change as well
  useOnDependencyChange(() => {
    employees.length < totalFilteredEmployees ? setHasMore(true) : setHasMore(false)
  }, [page, totalFilteredEmployees])

  const employeeCards = () => {

    return (
      <InfiniteScroll
        dataLength={employees.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<Typography>Loading...</Typography>}
      >
        <Grid justifyContent={'center'} container mt={3} >
          {employees?.map((employee) => (
            <Card
              key={employee._id}
              _id={employee._id}
              location={employee.location}
              level={employee.level}
              title={employee.jobTitle}
              yearsOfExperience={employee.yearsOfExperience}
              experience={employee.proExperience}
              primarySkills={employee.primarySkills}
              salary={employee.salaryRatePerHour}
              workModel={employee.workModel}
              start={employee.startInWeeks}
              contractType={employee.contractType}
            />
          ))}
        </Grid>
      </InfiniteScroll>)
  }

  return (
    <>
      <Stack
        minHeight={400}
        paddingBottom={10}
      >
        <Typography variant='h7' alignSelf='flex-end' style={{ position: 'sticky', top: '27%', marginRight: -10 }} >{totalFilteredEmployees + " / " + totalEmployees}</Typography>
        {employees.length !== 0
          ? employeeCards()
          : (
            <Stack display={'flex'} alignSelf={'center'} alignItems={'center'}>
              <img
                style={{ height: 200, width: 200 }}
                src='/no-data-found.png'
                alt='NO DATA FOUND'
              ></img>
              <Typography textAlign={'center'}
              >
                <FormattedMessage id='noCVFound' />
              </Typography>
            </Stack>
          )}
      </Stack>
      <SignInRegisterModal />
    </>
  )
}
