import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import {
  profileSliceSelector,
  setShowSignInRegisterModal,
} from '../profilePage/profileSlice'
import Modal from '@mui/material/Modal'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0.5px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

export default function SignInRegisterModal() {
  const dispatch = useDispatch()
  const { showSignInRegisterModal } = useSelector(profileSliceSelector)
  const navigate = useNavigate()

  const handleClose = () => {
    dispatch(setShowSignInRegisterModal(false))
  }

  const handleClick = (route) => {
    navigate(route)
    dispatch(setShowSignInRegisterModal(false))
  }

  return (
    <div>
      <Modal
        open={showSignInRegisterModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Typography variant='h6'>
              <FormattedMessage id='signUpOrRegister' />
            </Typography>
            <Box
              sx={{
                display: 'flex',
                direction: 'row',
                justifyContent: 'space-around',
                width: '100%',
                mt: 2,
              }}
            >
              <Button
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleClick('/sign-in')}
              >
              <FormattedMessage id='signin' />
              </Button>
              <Button
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleClick('/sign-up')}
              >
              <FormattedMessage id='signup' />
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
