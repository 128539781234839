import { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from "react-redux";
import { searchSliceSelector, setLocation, fetchLocations } from '../searchSlice';
import { Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function LocationsFilters() {
  const dispatch = useDispatch()
  const intl = useIntl();
  const { availableLocations, filter } = useSelector(searchSliceSelector)

  useEffect(() => {
    if (availableLocations.length === 0) {
      dispatch(fetchLocations())
    }
  }, [])

  const handleClick = (locationClicked) => {
    const isBoxChecked = filter.location.includes(locationClicked)
    if (isBoxChecked) {
      const newLocationsArray = [...filter.location].filter(selectedLocation => selectedLocation !== locationClicked)
      dispatch(setLocation(newLocationsArray))
      return
    }
    const newLocationsArray = [...filter.location];
    newLocationsArray.push(locationClicked);
    dispatch(setLocation(newLocationsArray))
  }

  const isBoxChecked = (location) => {
    return filter.location.includes(location)
  }

  return (
    <Autocomplete
      multiple
      limitTags={0}
      sx={{ bgcolor: 'whitesmoke', maxWidth: 200, borderRadius: '4px', marginLeft: 2 }}
      id="checkboxes-tags-demo"
      options={availableLocations}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <li {...props} onClick={() => handleClick(option)}
        >
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={isBoxChecked(option)}
          />
          {option}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} placeholder={intl.formatMessage({id:"selectLocation"})}/>
      )}
    />
  )
}