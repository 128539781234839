import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@mui/material';

const style = {
    position: 'absolute',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

export default function LicenceModal({ open, close }) {

    const handleSubmit = (event) => {
        event.preventDefault();
        close();
    };

    const emailLink = (
        <a href="mailto:jesido@maisourcer.de">maisourcer</a>
    );

    return (
        <Modal
            open={open}
            onClose={close}
        >
            <Box sx={style}>
                <Typography variant="body1" mb={3}>
                    <FormattedMessage
                        id="mustHaveLicence"
                        values={{ link: emailLink }}
                    />
                </Typography>
                <Button onClick={handleSubmit} variant="contained" color="primary" >
                    OK
                </Button>
            </Box>
        </Modal>
    );
}