import { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { authSliceSelector, setSelectedLanguage, setSignOutUser } from '../loginComponent/authSlice'
import { useDispatch } from 'react-redux'
import { profileSliceSelector } from '../profilePage/profileSlice'
import Select from '@mui/material/Select'
import { FormattedMessage } from 'react-intl'

export default function Header() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { authenticated, language } = useSelector(authSliceSelector)
  const { firstName, lastName } = useSelector(profileSliceSelector)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function createLettersForHeaderIcon() {
    // Take the first letter from firstname surname
    if (!firstName || !lastName) {
      return ''
    }

    const firstTwoLetters = firstName?.slice(0, 1) + lastName?.slice(0, 1)
    const capitalizedResult = firstTwoLetters?.toUpperCase()
    return capitalizedResult
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigateTo = (route) => {
    navigate('/' + route)
    handleClose()
  }

  const handleSignOut = () => {
    dispatch(setSignOutUser())
  }

  const handleLanguageChange = (e) => {
    dispatch(setSelectedLanguage(e.target.value))
  }

  return (
    <Box sx={{ flexGrow: 1,  }}>
      <AppBar  sx={{ bgcolor: 'white' }}>
        <Toolbar sx={{ width: '80%', alignSelf: 'center' ,height: 121, display: 'flex' }}>
          <Typography
            variant='h5'
            color='primary.dark'
            sx={{ flexGrow: 1, fontWeight: 'bold' }}
          >
            <img
              style={{ height: 80, width: 115, cursor: 'pointer' }}
              src='/logo.png'
              alt="MAISOURCER"
              onClick={() => navigate('/')}
            />
          </Typography>
          <Box sx={{ display: { xs: 'block', sm: 'block' } }}>
            <Link
              to='/'
              color='primary.dark'
              style={{ textDecoration: 'none' }}
            >
              <Button sx={{ color: 'primary.dark' }}>
                <Typography variant="h6" sx={{textTransform: 'none'}}>Home</Typography>
              </Button>
            </Link>
          </Box>
          {authenticated ? (
            <div>
              <Box
                sx={{
                  p: 1,
                  ml: 2,
                  backgroundColor: 'grey',
                  borderRadius: '125%',
                  cursor: 'pointer',
                  fontFamily: 'Futura-Bold'
                }}
                onClick={handleClick}
                children={createLettersForHeaderIcon()}
              />
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => navigateTo('profile')}>
                  <FormattedMessage id='myProfile' />
                </MenuItem>
                <MenuItem onClick={handleSignOut}><FormattedMessage id='logout' /></MenuItem>
              </Menu>
            </div>
          ) : (
            <Link to='sign-in' style={{ textDecoration: 'none' }}>
              <Button sx={{ color: 'primary.dark' }}>
                <Typography variant="h6" sx={{textTransform: 'none'}}><FormattedMessage id='signin' /></Typography>
              </Button>
            </Link>
          )}
          <Select
            sx={{ height: 40, ml: 2 }}
            value={language}
            onChange={handleLanguageChange}
          >
            <MenuItem value={'en'}>EN</MenuItem>
            <MenuItem value={'de'}>DE</MenuItem>
          </Select>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
