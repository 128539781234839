import { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import { buyCredits, profileSliceSelector } from '../profilePage/profileSlice'
import Modal from '@mui/material/Modal'
import { FormattedMessage } from 'react-intl'
import { getAllPackages } from '../../utils/apiCalls'
import { toast } from 'react-toastify'

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

function creditsSelectItem(pack) {
  const { _id, title, credits, price } = pack
  return (
    <MenuItem key={_id} value={_id}>
      {`${title}: ${credits} Credits (${price}€)`}
    </MenuItem>
  )
}

export default function CreditsModal(props) {
  const { _id, email } = useSelector(profileSliceSelector)
  const [selectedCredits, setSelectedCredits] = useState(null)
  const { open } = props
  const [creditPackages, setCreditsPackages] = useState([])
  const dispatch = useDispatch()

  const handleClose = () => {
    props.closeModal()
  }

  const handleChange = (event) => {
    const foundPackage = creditPackages.find(pack => pack._id === event.target.value)
    setSelectedCredits(foundPackage)
  }

  const handlePurchase = async () => {
    const body = {
      title: selectedCredits.title,
      credits: selectedCredits.credits,
      price: selectedCredits.price,
      _id,
      email,
      url: window.location.origin
    }
    dispatch(buyCredits(body))
  }

  useLayoutEffect(() => {
    (async () => {
      await getAllPackages().then(res => {
        if (res.data.length > 0) {
          setCreditsPackages(res.data)
        }
      }).catch(err => {
        toast.error(err)
      })
    })()
  }, [])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2' pb={5}>
            <FormattedMessage id='howManyCreditsToBuy' />
          </Typography>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'><FormattedMessage id='amount' /></InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedCredits?._id}
                label={<FormattedMessage id='amount' />}
                onChange={(event) => handleChange(event)}
              >
                {creditPackages.map(creditsSelectItem)}
              </Select>
            </FormControl>
          </Box>
          <Button
            disabled={selectedCredits === null}
            fullWidth
            variant='contained'
            sx={{ mt: 5, mb: 3 }}
            onClick={handlePurchase}
          >
            <FormattedMessage id='buyCredits' />
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
