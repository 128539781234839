import { Box, Button, Stack, Typography } from "@mui/material"
import { FormattedMessage } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useSelector } from "react-redux";
import { authSliceSelector } from "../loginComponent/authSlice";

export const PaymentSuccess = () => {
    const navigate = useNavigate()
    const { language } = useSelector(authSliceSelector)

    const credits = useParams().credits || null;

    const successTextToShow = language === 'de' ?
        `Deinem Konto wurden ${credits} Credits gutgeschrieben.` :
        `${credits} credits has beed added to your Account.`

    return (
        <Box sx={{
            marginTop: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Box
                sx={{
                    boxShadow: 3,
                    gap: 2,
                    py: 6,
                    px: 10,
                    borderRadius: '25px',
                    border: '1px solid #ddd',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CheckCircleRoundedIcon sx={{ fontSize: 60, color: 'green' }} />
                <Typography fontSize={22} pb={2}>{successTextToShow}</Typography>
                <Stack direction={'row'} gap={5}>
                    <Button
                        size='medium'
                        variant='outlined'
                        onClick={() => navigate('/profile')}
                    >
                        {/* <FormattedMessage id='Home' /> */}
                        OK
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
