import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Link as RouterLink } from 'react-router-dom'
import { authSliceSelector, signUp } from './authSlice';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default function SignUp() {
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    companyName: "",
    position: "",
    phoneNumber: "",
    address: "",
    email: "",
    password: "",
    newsletter: false,
    dataPolicy: false
  });
  const { language } = useSelector(authSliceSelector)
  const [emailError, setEmailError] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    if (email.length === 0 || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError(''); // Clear any previous error
    } else {
      setEmailError('Invalid email format');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "phoneNumber") {
      // Sanitize the phone input to contain only numbers
      const numericPhone = value.replace(/[^0-9+\-/]/g, '');
      setFormData({ ...formData, [name]: numericPhone });
    } else if (name === "email") {
      setFormData({ ...formData, [name]: value });
      validateEmail(value);
    } else {
      // Handle other fields
      const newValue = type === "checkbox" ? checked : value;
      setFormData({ ...formData, [name]: newValue });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (emailError) {
      // Don't submit the form if there is an email format error
      return;
    } else{
      const signUpData = {
        user: formData,
        navigate
      }
      dispatch(signUp(signUpData));
    }
  };

  const GermanDataPolicy = <p>Ich bin mit der <a target="_blank" href='https://www.maisourcer.de/privacy/' rel="noreferrer">Datenschutzerklärung</a> einverstanden</p>
  const EnglishDataPolicy = <p>I agree with the <a target="_blank" href='https://www.maisourcer.de/privacy/' rel="noreferrer">data policy</a></p>

  return (
    <Container maxWidth="sm" sx={{ mt: 20, mb: 20 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <FormattedMessage id='signup' />
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <FormControl fullWidth>
                <InputLabel id="select-label"><FormattedMessage id='gender' /></InputLabel>
                <Select
                  labelId="select-label"
                  type='text'
                  value={formData.title}
                  name='title'
                  label={<FormattedMessage id='gender' />}
                  onChange={handleChange}
                >
                  <MenuItem value={''}><FormattedMessage id='noChoice' /></MenuItem>
                  <MenuItem value={'Mr.'}><FormattedMessage id='mr' /></MenuItem>
                  <MenuItem value={'Mrs.'}><FormattedMessage id='mrs' /></MenuItem>
                  <MenuItem value={'Dr.'}><FormattedMessage id='dr' /></MenuItem>
                  <MenuItem value={'Prof.'}><FormattedMessage id='prof' /></MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <TextField
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                label={<FormattedMessage id='firstName' />}
                type='text'
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                type='text'
                required
                label={<FormattedMessage id='lastName' />}
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                required
                type='text'
                label={<FormattedMessage id='companyName' />}
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                type='text'
                value={formData.position}
                onChange={handleChange}
                label="Position"
                name="position"
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                required
                label={<FormattedMessage id='phone' />}
                name="phoneNumber"
                type='text'
                inputProps={{
                  inputMode: 'numeric'
                }}
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                required
                fullWidth
                label={<FormattedMessage id='companyAddress' />}
                name="address"
                type='text'
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                error={!!emailError}
                helperText={emailError}
                required
                fullWidth
                label={<FormattedMessage id='email' />}
                name="email"
                type='text'
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                required
                fullWidth
                name="password"
                label={<FormattedMessage id='password' />}
                type="password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item >
              <FormControlLabel
                control={
                  <Checkbox
                    type='checkbox'
                    name="newsletter"
                    color="primary"
                    checked={formData.newsletter}
                    onChange={handleChange}
                  />}
                label={<FormattedMessage id='iWantToReceiveInspiration' />}
              />
            </Grid>
            <Grid item >
              <FormControlLabel
                control={
                  <Checkbox
                    type='checkbox'
                    name="dataPolicy"
                    color="primary"
                    checked={formData.dataPolicy}
                    onChange={handleChange}
                  />}
                required
                label={language === 'de' ? GermanDataPolicy : EnglishDataPolicy}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            <FormattedMessage id='signup' />
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <RouterLink to='/sign-in' style={{ textDecoration: 'none' }}>
                <Link variant="body2" color='secondary.dark'>
                  <FormattedMessage id='alreadyHaveAccount' />
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}