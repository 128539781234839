import { baseURL } from "./constants"

const baseURI = `${baseURL}/api`

const userID = localStorage.getItem('userId')

export const SIGN_UP = `${baseURI}/users/`
export const SIGN_IN = `${baseURI}/users/auth`
export const TOKEN_AUTH = `${baseURI}/users/auth-token`
export const RESET_PASSWORD = `${baseURI}/users/reset-password`
export const FORGOT_PASSWORD = `${baseURI}/users/forgot-password`

export const GET_ALL_CVS = `${baseURI}/cv/`;
export const GET_ALL_LOCATIONS = `${baseURI}/cv/locations/all`;
export const GET_ALL_LANGUAGES = `${baseURI}/cv/languages/all`;
export const GET_COMPANIES_ADMIN = `${baseURI}/users/names`;
export const GET_COMPANIES_BY_ID_ADMIN = `${baseURI}/users/details`;
export const GET_FILTERED_CVS = `${baseURI}/cv/filter/`;
export const GET_RECOMMENDED_JOBS = `${baseURI}/cv/titles/`;
export const GET_RECOMMENDED_SKILLS= `${baseURI}/cv/skills/`;

export const CHANGE_USER_INFO = (usrID) => `${baseURI}/users/${usrID}/changeInfo`
// Sometimes the localstorage return null form the user ID so i put it from the state
export const CHANGE_USER_PASSWORD = (usrID) =>`${baseURI}/users/${usrID}/changePassword`
export const CREATE_CV = `${baseURI}/cv/create`

export const GET_CV_FOR_MODAL = (cvID) => `${baseURI}/cv/get-cv-for-modal/${cvID}`

export const EDIT_COMPANY_CREDITS = `${baseURI}/users/credits`;
export const DELETE_ACCOUNT_REQUEST = `${baseURI}/users/${userID}/delete-request`;
export const CREATE_PAYMENT_REQUEST = `${baseURI}/payments/create-checkout`;

export const GET_ALL_PACKAGES = `${baseURI}/packages`