import { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useSelector, useDispatch } from "react-redux";
import { searchSliceSelector, fetchLanguages, setLanguage } from '../searchSlice';
import { Autocomplete, Divider } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import _, { toLower } from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function LanguagesFilters() {
  const dispatch = useDispatch()
  const intl = useIntl();
  const { availableLanguages, filter } = useSelector(searchSliceSelector)

  const germanAndEnglish = ['deutsch', 'englisch'];
  const otherLanguages = availableLanguages
    .filter(language => !germanAndEnglish.includes(language))

  const sortedLanguages = [...germanAndEnglish, '---', ...otherLanguages];



  useEffect(() => {
    if (availableLanguages.length === 0) {
      dispatch(fetchLanguages())
    }
  }, [])

  const handleClick = (languageClicked) => {
    languageClicked = toLower(languageClicked);
    const isBoxChecked = filter.language.includes(languageClicked)
    if (isBoxChecked) {
      const newLanguagesArray = [...filter.language].filter(selectedLanguage => selectedLanguage !== languageClicked)
      dispatch(setLanguage(newLanguagesArray))
      return
    }
    const newLanguagesArray = [...filter.language];
    newLanguagesArray.push(languageClicked);
    dispatch(setLanguage(newLanguagesArray))
  }

  const isBoxChecked = (language) => {
    return filter.language.includes(language)
  }

  return (
    <Autocomplete
      multiple
      limitTags={0}
      sx={{ bgcolor: 'whitesmoke', maxWidth: 200, borderRadius: '4px', marginLeft: 2 }}
      id="checkboxes-tags-demo"
      options={sortedLanguages}
      disableCloseOnSelect
      getOptionLabel={(option) => option === '---' ? '' : option}
      getOptionDisabled={(option) =>
        option === '---'
      }
      renderOption={(props, option) => (
        <li {...props} onClick={() => handleClick(option)}>
          {option === '---' ? <Divider sx={{
            borderColor: 'lightgrey',
            borderWidth: '1px', width: '100%'
          }} /> : (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={isBoxChecked(option)}
              />
              {_.capitalize(option)}
            </>
          )}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} placeholder={intl.formatMessage({ id: "selectLanguage" })} />
      )}
    />
  )
}