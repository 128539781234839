import { takeLatest, put, call } from 'redux-saga/effects';
import { setEmployeesReducer, fetchEmployees, filterEmployees, setTotalEmployeesReducer, setTotalFilteredEmployees } from './employeeSlice';
import { searchSliceSelector } from './../sideBar/searchSlice';
import { getAllCVs, getFilteredCVs } from '../../utils/apiCalls';
import { select } from 'redux-saga/effects';
import { setLoading } from './../../app/slice';

function* fetchAllCVsSaga() {
    try {
        yield put(setLoading(true));
        const res = yield call(getAllCVs);
        yield put(setEmployeesReducer(res));
        yield put(setLoading(false));

    } catch (err) {
        yield put(setLoading(false));
        console.error('New error', err);
    }
}

function* filterEmployeesSaga() {
    try {
        yield put(setLoading(true));
        const { filter, page, pageSize } = yield select(searchSliceSelector);
        const onlyTitles = filter.jobTitle.filter(label => label.selected === true).map(label => label.jobTitle)
        const onlySkills = filter.skillTitle.filter(label => label.selected === true).map(label => label.skillTitle)

        const res = yield call(getFilteredCVs, { ...filter, jobTitle: onlyTitles, skillTitle: onlySkills, page, pageSize });
        const filteredTotal = res.filteredCVs[0].count[0]?.total ? res.filteredCVs[0].count[0].total : 0

        yield put(setEmployeesReducer(res.filteredCVs[0].cvs));
        yield put(setTotalFilteredEmployees(filteredTotal))
        yield put(setTotalEmployeesReducer(res.count))
        yield put(setLoading(false));

    } catch (err) {
        yield put(setLoading(false));
        console.error('New error', err);
    }
}

export function* watchEmployeesSaga() {
    yield takeLatest(fetchEmployees.type, fetchAllCVsSaga);
    yield takeLatest(filterEmployees.type, filterEmployeesSaga);
}
