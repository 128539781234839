import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, TextField, Typography, Stack } from '@mui/material'
import {
  changeUserInfo,
  profileSliceSelector,
} from '../profilePage/profileSlice'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

export default function ChangePersonalInformationModal(props) {
  const dispatch = useDispatch()
  const { _id } = useSelector(profileSliceSelector)
  const profileState = useSelector(profileSliceSelector)
  const [firstName, setFirstName] = useState(profileState.firstName)
  const [lastName, setLastName] = useState(profileState.lastName)
  const [email, setEmail] = useState(profileState.email)
  const [companyName, setCompanyName] = useState(profileState.companyName)
  const [address, setAddress] = useState(profileState.address)
  const [position, setPosition] = useState(profileState.position)
  const [phoneNumber, setPhoneNumber] = useState(profileState.phoneNumber)

  const handleClose = () => {
    props.close()
  }

  const save = () => {
    const body = {
      closeModal: props.close(),
      _id,
      firstName,
      lastName,
      email,
      companyName,
      address,
      position,
      phoneNumber
    }
    dispatch(changeUserInfo(body))
  }

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component='h1' variant='h5'>
              <FormattedMessage id='changePersonalInfo' />
            </Typography>
            <Stack spacing={2} sx={{ mt: 3 }}>
              <Stack direction={'row'} spacing={1}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  value={firstName}
                  label={<FormattedMessage id="firstName" />}
                  name='firstName'
                  onChange={(event) => setFirstName(event.target.value)}
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  value={lastName}
                  label={<FormattedMessage id="lastName" />}
                  name='lastName'
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  defaultValue={companyName}
                  label={<FormattedMessage id="companyName" />}
                  name='companyName'
                  onChange={(event) => setCompanyName(event.target.value)}
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  value={position}
                  label='Position'
                  name='position'
                  onChange={(event) => setPosition(event.target.value)}
                />
              </Stack>
              <TextField
                margin='normal'
                required
                fullWidth
                value={email}
                label='Email'
                name='email'
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                defaultValue={address}
                label={<FormattedMessage id="address" />}
                name='address'
                onChange={(event) => setAddress(event.target.value)}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                value={phoneNumber}
                label={<FormattedMessage id="phone" />}
                name='phoneNumber'
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
              <Stack direction={'row'} justifyContent={'space-between'} spacing={4}>
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ bgcolor: 'primary.dark' }}
                  onClick={handleClose}
                >
                  <FormattedMessage id='close' />
                </Button>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  onClick={() => save()}
                >
                  <FormattedMessage id='save' />
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
